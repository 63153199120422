import { FC } from "react";
import { motion } from "framer-motion";
import { HiCloud, HiShieldCheck, HiLockClosed } from "react-icons/hi";

const features = [
  {
    icon: HiCloud,
    title: "Flexible Deployment Options",
    description:
      "Deploy on your private cloud or securely on Quantly's infrastructure",
  },
  {
    icon: HiShieldCheck,
    title: "No Model Finetuning",
    description:
      "Maintain data privacy with models that don't learn from your sensitive information",
  },
  {
    icon: HiLockClosed,
    title: "Highest Compliance Standards",
    description: "SOC 2 Type II certified with GDPR and CCPA compliance",
  },
];

const SecuritySection: FC = () => {
  return (
    <div className="mb-32">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center transform-gpu"
      >
        <div>
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Enterprise-Grade Security
          </h2>
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            className="mb-8 transform-gpu"
          >
            <motion.path
              d="M60 20L25 35V55C25 82.5 39.5 108.5 60 115C80.5 108.5 95 82.5 95 55V35L60 20Z"
              stroke="currentColor"
              strokeWidth="4"
              fill="none"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
            />
            <motion.path
              d="M50 60L57 67L70 54"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.8, delay: 1 }}
            />
          </motion.svg>
        </div>
        <div className="grid gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl hover:shadow-lg transition-shadow duration-300 transform-gpu"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="transform-gpu"
              >
                <feature.icon className="w-6 h-6 text-black flex-shrink-0" />
              </motion.div>
              <div>
                <h3 className="text-gray-900 font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SecuritySection;
