import { FC } from "react";
import { motion } from "framer-motion";
import { HiDatabase, HiChip, HiCloud, HiViewGrid } from "react-icons/hi";

const steps = [
  {
    icon: HiDatabase,
    title: "We Integrate Your Internal Data",
    description: "We integrate and combine your internal data sources with 3rd party data, to create a single source of truth for analysts.",
    iconGradient: "from-blue-400 to-blue-600",
  },
  {
    icon: HiChip,
    title: "Context-Aware LLM Layer",
    description: "We fit our LLM orchestration on your corpus for a deep understanding of your company's context, processes and requirements.",
    iconGradient: "from-purple-400 to-purple-600",
  },
  {
    icon: HiViewGrid,
    title: "Extensible And Open Infrastructure",
    description: "We provide the base LLM infra layer. You can extend our tech and build for your own use cases and processes internally.",
    iconGradient: "from-orange-400 to-orange-600",
  },
  {
    icon: HiCloud,
    title: "On-Premise or Cloud Deployment",
    description: "We can deploy the system in any way that suits you - on your infrastructure or ours.",
    iconGradient: "from-green-400 to-green-600",
  },
];

const WhatWeDoAltSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 text-center mb-4 whitespace-nowrap"
        >
          We Build Enterprise-Grade Infrastructure for Leveraging LLMs
        </motion.h2>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="flex justify-center mb-16"
        >
          <div className="inline-flex items-center gap-2 bg-white border border-gray-100 rounded-full px-6 py-2.5 shadow-sm">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
            <span className="text-gray-800 desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs font-medium tracking-wide">
              AI-POWERED INVESTMENT RESEARCH
            </span>
          </div>
        </motion.div>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-6">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group h-full"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-gray-800 shadow-lg hover:shadow-xl transition-all duration-300 h-full flex flex-col"
                whileHover={{ scale: 1.02 }}
              >
                <div className="w-14 h-14 bg-white/10 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                  <step.icon className="w-7 h-7 text-white" />
                </div>
                <h3 className="text-xl font-bold text-white mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {step.title}
                </h3>
                <p className="text-gray-400 leading-relaxed text-base group-hover:translate-y-[-2px] transition-transform duration-300">
                  {step.description}
                </p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoAltSlide;
