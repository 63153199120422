import { FC } from "react";
import { motion } from "framer-motion";
import { HiUserGroup, HiOfficeBuilding, HiGlobeAlt } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const WhoWeAreSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="space-y-12">
        {/* Main Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center space-y-6"
        >
          <h2 className="text-4xl font-bold text-gray-900">Who We Are</h2>
          <p className="text-2xl text-gray-600 max-w-4xl mx-auto leading-relaxed">
            Quantly allows financial institutions to leverage LLMs by
            integrating with all sources of internal data and combining them
            with external data sources, in a single platform. Deployed on your
            infrastructure or on the cloud.
          </p>
        </motion.div>

        {/* Client Base and Investors Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8"
        >
          {/* Financial Institutions */}
          <div className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl relative group hover:shadow-2xl hover:bg-gray-50 transition-all duration-300">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Deployed in production to:</h3>
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0">
                <div className="w-16 h-16 bg-gray-100 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                  <HiUserGroup className="w-8 h-8 text-gray-900" />
                </div>
              </div>
              <div className="text-gray-600 text-xl">
                Analysts from <span className="font-semibold text-gray-900">20+</span> financial institutions
              </div>
            </div>
          </div>

          {/* Investors */}
          <div className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl relative group hover:shadow-2xl hover:bg-gray-50 transition-all duration-300">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Backed by:</h3>
            <div className="flex items-center justify-center gap-4">
              <img
                src="https://presentations.quantly-ai.com/Group%203555.png"
                alt="Investors"
                className="h-16 w-auto"
              />
              <p className="text-gray-600">+ notable angels</p>
            </div>
          </div>
        </motion.div>

        {/* Team Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8"
        >
          {/* CEO Card */}
          <motion.div
            className="bg-gradient-to-br from-gray-900 via-black to-gray-900 shadow-2xl border border-gray-800 p-8 rounded-2xl hover:shadow-[0_20px_50px_rgba(0,0,0,0.3)] transition-all duration-300 relative"
            whileHover={{ scale: 1.02 }}
          >
            <img
              src="https://presentations.quantly-ai.com/Group%203551.png"
              alt="Previous Companies"
              className="absolute top-8 right-8 h-24 w-auto object-contain"
            />
            <div className="relative z-10">
              <img
                src="https://createsiteai.b-cdn.net/c/_/d757bb9b-9524-42cc-81ed-0c5cdad3df5f.jpg"
                alt="Vlad Stanev"
                className="w-24 h-24 rounded-full border border-gray-800 mb-4 transform group-hover:scale-110 transition-all duration-300"
              />
              <h3 className="text-2xl font-bold text-white mb-4">Vlad Stanev</h3>
              <div className="text-lg text-gray-400 mb-2">CEO</div>
              <ul className="space-y-2 text-gray-300">
                <li>• Former sell-side analyst</li>
                <li>• Two-time founder</li>
                <li>
                  • Built, scaled and exited a financial data annotation company managing 100+ analysts
                </li>
              </ul>
            </div>
          </motion.div>

          {/* CTO Card */}
          <motion.div
            className="bg-gradient-to-br from-gray-900 via-black to-gray-900 shadow-2xl border border-gray-800 p-8 rounded-2xl hover:shadow-[0_20px_50px_rgba(0,0,0,0.3)] transition-all duration-300 relative"
            whileHover={{ scale: 1.02 }}
          >
            <img
              src="https://presentations.quantly-ai.com/Group%203553.png"
              alt="Previous Companies"
              className="absolute top-8 right-8 h-24 w-auto object-contain"
            />
            <div className="relative z-10">
              <img
                src="https://createsiteai.b-cdn.net/c/_/a1d4f7ef-4146-4453-8808-7d038ad21f8b.jpg"
                alt="Ivan Kavalerov"
                className="w-24 h-24 rounded-full border border-gray-800 mb-4 transform group-hover:scale-110 transition-all duration-300"
              />
              <h3 className="text-2xl font-bold text-white mb-4">Ivan Kavalerov</h3>
              <div className="text-lg text-gray-400 mb-2">CTO</div>
              <ul className="space-y-2 text-gray-300">
                <li>
                  • Three-time founder, including pioneering ML model compression
                  startup
                </li>
                <li>
                  • Ex-Head of Product at Ably (Series B data infrastructure company). Led scaling to support 250M+ connected devices.
                </li>
                <li>• 11 years of data/AI company scaling expertise</li>
              </ul>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default WhoWeAreSlide;
