import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiLightBulb,
  HiDocumentSearch,
  HiChat,
  HiChartBar,
  HiQuestionMarkCircle,
  HiSparkles,
  HiScale,
  HiTemplate,
} from "react-icons/hi";

const useCases = [
  {
    icon: HiDocumentSearch,
    title: "New Company Coverage",
    description:
      "Quickly analyze and understand new companies for coverage expansion",
  },
  {
    icon: HiLightBulb,
    title: "Deep Dive on Any Research Topic",
    description: "Comprehensive analysis of specific topics or themes",
  },
  {
    icon: HiChat,
    title: "Earnings Call Language Analysis",
    description: "Extract insights from management communication patterns",
  },
  {
    icon: HiChartBar,
    title: "Company and Sector KPIs Extraction",
    description: "Automated extraction of key performance metrics",
  },
  {
    icon: HiQuestionMarkCircle,
    title: "Questions for Management",
    description: "Generate insightful questions for company management",
  },
  {
    icon: HiSparkles,
    title: "Idea Generation",
    description: "Discover new investment opportunities and themes",
  },
  {
    icon: HiScale,
    title: "Bull & Bear Scenario Analysis",
    description: "Comprehensive scenario analysis for investment cases",
  },
  {
    icon: HiTemplate,
    title: "Any Other Research Use Case",
    description: "Flexible platform adaptable to your specific needs",
  },
];

const UseCasesSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Use Cases
        </motion.h2>
        <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {useCases.map((useCase, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl text-white hover:scale-105 transition-all duration-300 group"
            >
              <useCase.icon className="w-8 h-8 mb-4 text-white/80 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-lg font-semibold mb-2 group-hover:translate-y-[-2px] transition-transform duration-300">
                {useCase.title}
              </h3>
              <p className="text-gray-300 text-sm group-hover:translate-y-[-2px] transition-transform duration-300">
                {useCase.description}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCasesSlide;
