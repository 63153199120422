import { FC } from "react";
import { motion } from "framer-motion";
import { HiShieldCheck, HiLockClosed, HiServer, HiUsers } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";
import SecurityShield from "./components/SecurityShield";

const SecurityConcernsSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12 text-center"
      >
        The Main Hurdle: Data Privacy & Compliance
      </motion.h2>
      <SecurityShield />
    </MatrixSlideLayout>
  );
};

export default SecurityConcernsSlide;
