import { FC } from "react";
import { motion } from "framer-motion";
import { HiDownload } from "react-icons/hi";

const BusinessCard: FC = () => {
  const handleDownload = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (!element) return;

    const printWindow = window.open("", "_blank");
    if (!printWindow) return;

    printWindow.document.write(`
      <html>
        <head>
          <title>Business Card - ${elementId}</title>
          <style>
            @page {
              margin: 0;
              size: 88mm 59mm;
            }
            body {
              margin: 0;
              padding: 0;
            }
            svg {
              width: 88mm;
              height: 59mm;
            }
          </style>
        </head>
        <body>
          ${element.outerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className="min-h-screen bg-[#FAFAFA] py-24">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          Business Card
        </motion.h1>

        <div className="space-y-12">
          {/* Front Card */}
          <div className="space-y-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className="w-[332px] h-[223px] mx-auto"
            >
              <svg
                id="card-front"
                width="332"
                height="223"
                viewBox="0 0 332 223"
                className="w-full h-full shadow-lg rounded-lg"
              >
                <rect width="332" height="223" fill="white" rx="8" />
                <image
                  href="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
                  x="32"
                  y="32"
                  width="48"
                  height="48"
                />
                <text
                  x="32"
                  y="120"
                  className="text-xl font-bold"
                  fill="#111827"
                >
                  John Smith
                </text>
                <text x="32" y="144" fill="#4B5563">
                  Head of Investment Research
                </text>
                <text x="32" y="180" fill="#4B5563" fontSize="14">
                  +44 (0) 123 456 789
                </text>
                <text x="32" y="200" fill="#4B5563" fontSize="14">
                  john.smith@quantly-ai.com
                </text>
              </svg>
            </motion.div>
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              onClick={() => handleDownload("card-front")}
              className="flex items-center gap-2 mx-auto px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-800 transition-colors duration-300"
            >
              <HiDownload className="w-5 h-5" />
              <span>Download Front</span>
            </motion.button>
          </div>

          {/* Back Card */}
          <div className="space-y-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2 }}
              className="w-[332px] h-[223px] mx-auto"
            >
              <svg
                id="card-back"
                width="332"
                height="223"
                viewBox="0 0 332 223"
                className="w-full h-full shadow-lg rounded-lg"
              >
                <rect width="332" height="223" fill="white" rx="8" />
                <image
                  href="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
                  x="116"
                  y="61.5"
                  width="100"
                  height="100"
                />
              </svg>
            </motion.div>
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              onClick={() => handleDownload("card-back")}
              className="flex items-center gap-2 mx-auto px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-800 transition-colors duration-300"
            >
              <HiDownload className="w-5 h-5" />
              <span>Download Back</span>
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
