import { FC, useState } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const temporalElements = [
  {
    id: "timeframe",
    text: "annual reports from 2021, 2022, and 2023",
    description: "Time period for analysis",
  },
  {
    id: "section",
    text: "Risk Factors sections",
    description: "Specific document section",
  },
  {
    id: "analysis",
    text: "Identify any new risks that have been added, risks that have been removed, and changes in the emphasis or description of persistent risks",
    description: "Analysis objectives",
  },
  {
    id: "insight",
    text: "What might these changes suggest about the evolution of the company's business environment",
    description: "Insight generation",
  },
];

const TemporalAnalysisSlide: FC = () => {
  const [activeElements, setActiveElements] = useState<string[]>([]);

  const toggleElement = (element: string) => {
    if (activeElements.includes(element)) {
      setActiveElements(activeElements.filter((e) => e !== element));
    } else {
      setActiveElements([...activeElements, element]);
    }
  };

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Incorporating Temporal Analysis in Prompts
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
        {/* Left Side - Concept Explanation */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <p className="text-xl mb-6">
            It allows us to compare information across time:
          </p>
          <ul className="space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-xl text-white/80"
            >
              • Design prompts that explicitly compare information across
              different time periods to identify trends, shifts, or cyclical
              patterns in financial data.
            </motion.li>
          </ul>
        </motion.div>

        {/* Right Side - Interactive Example */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <h3 className="text-xl font-semibold mb-6">Interactive Example</h3>
          <p className="text-xl text-white/80 mb-6">
            Click elements to analyze the structure:
          </p>

          <div className="space-y-4">
            <p className="text-lg font-mono p-4 bg-black/30 rounded-xl">
              "Compare the{" "}
              <span
                onClick={() => toggleElement("section")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("section")
                    ? "text-blue-400"
                    : "text-white/80 hover:text-blue-400/80"
                }`}
              >
                'Risk Factors' sections
              </span>{" "}
              of Company Z's{" "}
              <span
                onClick={() => toggleElement("timeframe")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("timeframe")
                    ? "text-purple-400"
                    : "text-white/80 hover:text-purple-400/80"
                }`}
              >
                annual reports from 2021, 2022, and 2023
              </span>
              .{" "}
              <span
                onClick={() => toggleElement("analysis")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("analysis")
                    ? "text-green-400"
                    : "text-white/80 hover:text-green-400/80"
                }`}
              >
                Identify any new risks that have been added, risks that have
                been removed, and changes in the emphasis or description of
                persistent risks
              </span>
              .{" "}
              <span
                onClick={() => toggleElement("insight")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("insight")
                    ? "text-yellow-400"
                    : "text-white/80 hover:text-yellow-400/80"
                }`}
              >
                What might these changes suggest about the evolution of the
                company's business environment
              </span>
              ?"
            </p>

            <div className="space-y-2">
              {temporalElements.map(
                (part) =>
                  activeElements.includes(part.id) && (
                    <motion.div
                      key={part.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="bg-white/10 p-4 rounded-xl"
                    >
                      <span className="text-sm font-medium">
                        {part.description}:
                      </span>{" "}
                      <span className="text-white/80">{part.text}</span>
                    </motion.div>
                  ),
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default TemporalAnalysisSlide;
