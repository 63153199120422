import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiShieldCheck, HiPuzzle } from "react-icons/hi";

const objectives = [
  {
    icon: HiLightningBolt,
    title: "Product-Market Fit Validation",
    description: [
      "Validate Quantly's enterprise AI infrastructure proposition",
      "Refine product roadmap based on deep understanding of processes and pain points"
    ]
  },
  {
    icon: HiShieldCheck,
    title: "Enterprise Readiness",
    description: [
      "Learn and implement 'table stakes' security and deployment requirements",
      "Enable seamless integration with internal data to maximize research value"
    ]
  },
  {
    icon: HiPuzzle,
    title: "Build a Category-Defining Product",
    description: [
      "Create the go-to LLM infrastructure for financial institutions",
      "Partner with technology leaders to deliver indispensable solutions"
    ]
  }
];

const WhyFILSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto w-full">
        <div className="mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-5xl font-bold text-gray-900 mb-4"
          >
            Why FIL?
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-gray-600 max-w-3xl"
          >
            The Fintech Innovation Lab is the perfect opportunity to develop Quantly into an essential part of a financial institution's tech stack.
          </motion.p>
        </div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {objectives.map((objective, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 + index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className="relative bg-white p-8 rounded-2xl border border-gray-200 shadow-lg hover:shadow-xl transition-all duration-300 h-full"
                whileHover={{ scale: 1.02 }}
              >
                <div className="w-14 h-14 bg-gray-900/5 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  <objective.icon className="w-7 h-7 text-gray-900" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {objective.title}
                </h3>
                <ul className="space-y-3">
                  {objective.description.map((point, i) => (
                    <li key={i} className="text-gray-600 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                      {point}
                    </li>
                  ))}
                </ul>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyFILSlide; 