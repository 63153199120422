import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDocumentText,
  HiLightningBolt,
  HiChartBar,
  HiCode,
} from "react-icons/hi";
import MatrixSlideLayout from "../../ProductSlides/layouts/MatrixSlideLayout";

const developments = [
  {
    icon: HiDocumentText,
    title: "Expanded Context Windows",
    description:
      "Larger context windows enable models to handle bigger documents and more data, allowing for easier and more reliable synthesis of information.",
    gradient: "from-blue-900 via-blue-800 to-blue-900",
  },
  {
    icon: HiLightningBolt,
    title: "Advancements in AI Reasoning",
    description:
      "OpenAI's O1 model demonstrates advanced reasoning, with research also being done in the area of integrating LLMs with Symbolic AI to enhance interpretability.",
    gradient: "from-purple-900 via-purple-800 to-purple-900",
  },
  {
    icon: HiChartBar,
    title: "Finance-specific Benchmarks",
    description:
      "FinanceBench is the industry benchmark for financial Q&A LLM accuracy. Performance of LLM applications keep improving, with Quantly achieving a top accuracy of 94%.",
    gradient: "from-indigo-900 via-indigo-800 to-indigo-900",
  },
  {
    icon: HiCode,
    title: "Text to Code",
    description:
      "Turning text to code techniques improve the reliability of LLM answers, especially when it comes to queries that require calculations of financial metrics or more complicated data analysis.",
    gradient: "from-green-900 via-green-800 to-green-900",
  },
];

const DesignTwo: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Recent Research and Developments
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {developments.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
            <motion.div
              className={`relative bg-gradient-to-br ${item.gradient} p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500`}
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <item.icon className="w-12 h-12 text-white mb-6 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-xl font-semibold text-white mb-4 group-hover:translate-y-[-2px] transition-transform duration-300">
                {item.title}
              </h3>
              <p className="text-white/80 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                {item.description}
              </p>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </MatrixSlideLayout>
  );
};

export default DesignTwo;
