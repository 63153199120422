import { FC, useState } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const hypothesisElements = [
  {
    id: "timeframe",
    text: "last three years",
    description: "Historical timeframe",
  },
  {
    id: "source",
    text: "financial reports",
    description: "Data source",
  },
  {
    id: "hypothesis",
    text: "shift to subscription-based services is improving profit margins",
    description: "Core hypothesis",
  },
  {
    id: "action",
    text: "find evidence supporting or refuting",
    description: "Analysis objective",
  },
];

const HypothesisDrivenPromptSlide: FC = () => {
  const [activeElements, setActiveElements] = useState<string[]>([]);

  const toggleElement = (element: string) => {
    if (activeElements.includes(element)) {
      setActiveElements(activeElements.filter((e) => e !== element));
    } else {
      setActiveElements([...activeElements, element]);
    }
  };

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Hypothesis-Driven Prompts
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
        {/* Left Side - Concept Explanation */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <p className="text-xl mb-6">
            Test your hypothesis on historical data:
          </p>
          <ul className="space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-xl text-white/80"
            >
              • Start with an investment hypothesis and design prompts to test
              it.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-xl text-white/80"
            >
              • This approach focuses the AI's analysis on specific aspects of
              your theory.
            </motion.li>
          </ul>
        </motion.div>

        {/* Right Side - Interactive Example */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <h3 className="text-xl font-semibold mb-6">Interactive Example</h3>
          <p className="text-xl text-white/80 mb-6">
            Click elements to analyze the structure:
          </p>

          <div className="space-y-4">
            <p className="text-lg font-mono p-4 bg-black/30 rounded-xl">
              "Analyze Company X's{" "}
              <span
                onClick={() => toggleElement("timeframe")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("timeframe")
                    ? "text-blue-400"
                    : "text-white/80 hover:text-blue-400/80"
                }`}
              >
                last three years
              </span>{" "}
              of{" "}
              <span
                onClick={() => toggleElement("source")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("source")
                    ? "text-purple-400"
                    : "text-white/80 hover:text-purple-400/80"
                }`}
              >
                financial reports
              </span>{" "}
              to{" "}
              <span
                onClick={() => toggleElement("action")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("action")
                    ? "text-green-400"
                    : "text-white/80 hover:text-green-400/80"
                }`}
              >
                find evidence supporting or refuting
              </span>{" "}
              the hypothesis that their{" "}
              <span
                onClick={() => toggleElement("hypothesis")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("hypothesis")
                    ? "text-yellow-400"
                    : "text-white/80 hover:text-yellow-400/80"
                }`}
              >
                shift to subscription-based services is improving profit margins
              </span>
              ."
            </p>

            <div className="space-y-2">
              {hypothesisElements.map(
                (part) =>
                  activeElements.includes(part.id) && (
                    <motion.div
                      key={part.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="bg-white/10 p-4 rounded-xl"
                    >
                      <span className="text-sm font-medium">
                        {part.description}:
                      </span>{" "}
                      <span className="text-white/80">{part.text}</span>
                    </motion.div>
                  ),
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default HypothesisDrivenPromptSlide;
