import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiDatabase, HiShieldCheck } from "react-icons/hi";
import MatrixSlideLayout from "./layouts/MatrixSlideLayout";

const features = [
  {
    icon: HiLightningBolt,
    title: "Instant Research Insights",
    description:
      "Focus on asking the right questions rather than doing manual searching",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
  {
    icon: HiDatabase,
    title: "Single Source of Truth",
    description:
      "Combine fundamental data with your internal research and notes",
    gradient: "from-purple-500/20 via-indigo-500/20 to-purple-500/20",
  },
  {
    icon: HiShieldCheck,
    title: "Secure and Compliant",
    description:
      "Enterprise-grade security and compliance to keep your research safe",
    gradient: "from-indigo-500/20 via-blue-500/20 to-indigo-500/20",
  },
];

const KeyFeaturesSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="flex flex-col items-center justify-center min-h-[80vh]">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          Key Features
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="relative group"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${feature.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
              />

              <motion.div
                className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 h-full"
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
              >
                <feature.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />

                <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                  {feature.title}
                </h3>

                <p className="text-white/80 text-lg group-hover:translate-y-[-2px] transition-transform duration-300">
                  {feature.description}
                </p>

                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default KeyFeaturesSlide;
