import { FC } from "react";
import { motion } from "framer-motion";
import AnimatedGridBackground from "./components/AnimatedGridBackground";

const GenAICapabilitiesSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900/90 via-black/90 to-gray-900/90 flex items-center justify-center">
        <motion.h1
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="desktop:text-7xl laptop:text-7xl tablet:text-6xl mobile:text-5xl font-bold text-white text-center relative z-20 drop-shadow-[0_0_15px_rgba(255,255,255,0.5)]"
        >
          Give GenAI capabilities to every analyst
        </motion.h1>
        <AnimatedGridBackground />
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
          initial={{ x: "-200%" }}
          animate={{ x: "200%" }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
            ease: "linear",
          }}
        />
      </div>
    </div>
  );
};

export default GenAICapabilitiesSlide;
