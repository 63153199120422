import { FC } from "react";
import { motion } from "framer-motion";
import { HiOutlineLightBulb, HiOutlineScale } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const statusItems = [
  {
    value: "2 Seats",
    description: "Limited deployment scope",
  },
  {
    value: "$3,000",
    description: "Per seat investment",
  },
];

const limitations = [
  {
    title: "Limited GenAI Adoption",
    description: "Analysts will not adopt or start getting used to GenAI",
  },
  {
    title: "Loss of First Mover Advantage",
    description:
      "Lost opportunity to be the first bank to adopt GenAI in research",
  },
  {
    title: "Restricted Product Feedback",
    description: "Limited feedback loop to improve platform for your use cases",
  },
];

const CurrentSituationSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-8"
      >
        Current Situation
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 p-8 rounded-2xl text-white"
        >
          <HiOutlineLightBulb className="w-8 h-8 text-white/80 mb-6" />
          <h3 className="text-2xl font-semibold mb-6">Current Status</h3>
          <div className="space-y-6">
            {statusItems.map((item, index) => (
              <div key={index} className="bg-white/10 p-4 rounded-xl">
                <div className="text-3xl font-bold mb-2">{item.value}</div>
                <p className="text-white/80">{item.description}</p>
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-purple-900 via-purple-800 to-purple-900 p-8 rounded-2xl text-white"
        >
          <HiOutlineScale className="w-8 h-8 text-white/80 mb-6" />
          <h3 className="text-2xl font-semibold mb-6">Current Limitations</h3>
          <div className="space-y-4">
            {limitations.map((limitation, index) => (
              <div key={index} className="bg-white/10 p-4 rounded-xl">
                <h4 className="font-semibold mb-2">{limitation.title}</h4>
                <p className="text-white/80">{limitation.description}</p>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default CurrentSituationSlide;
