import { FC } from "react";
import { motion } from "framer-motion";

const patronusData = [
  { name: "Quantly", accuracy: 94, color: "bg-blue-500" },
  { name: "GPTo1-preview", accuracy: 62.4, color: "bg-gray-400" },
  { name: "Claude3.5 Sonnet", accuracy: 56.7, color: "bg-gray-400" },
  { name: "GPT4o-mini", accuracy: 52.0, color: "bg-gray-400" },
];

const AccuracyChart: FC<{ data: typeof patronusData; source: string }> = ({ data, source }) => (
  <div className="flex flex-col items-center justify-center h-full">
    <div className="flex items-end justify-center space-x-16 h-[400px]">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col items-center">
          <motion.div 
            className="relative w-32"
            initial={{ height: 0, opacity: 0, scale: 0.8 }}
            animate={{ 
              height: `${item.accuracy * 3}px`, 
              opacity: 1, 
              scale: 1,
              transition: {
                height: {
                  duration: 1.2,
                  delay: index * 0.2,
                  ease: "easeOut"
                },
                opacity: {
                  duration: 0.3,
                  delay: index * 0.2
                },
                scale: {
                  duration: 0.4,
                  delay: index * 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            <motion.div 
              className={`absolute bottom-0 w-full ${item.color} rounded-t-lg shadow-lg`} 
              style={{ height: `${item.accuracy * 3}px` }}
              initial={{ boxShadow: "0 0 0 rgba(0,0,0,0)" }}
              animate={{ 
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                transition: { delay: (index * 0.2) + 0.8, duration: 0.3 }
              }}
            >
              <motion.div 
                className="absolute -top-8 w-full text-center text-gray-900 font-bold"
                initial={{ opacity: 0, y: 10 }}
                animate={{ 
                  opacity: 1, 
                  y: 0,
                  transition: { delay: (index * 0.2) + 1, duration: 0.3 }
                }}
              >
                {item.accuracy}%
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      ))}
    </div>

    <div className="flex justify-center space-x-16 mt-8">
      {data.map((item, index) => (
        <motion.div 
          key={index} 
          className="w-32 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ 
            opacity: 1, 
            y: 0,
            transition: { delay: (index * 0.2) + 1.2, duration: 0.4 }
          }}
        >
          <div className="text-gray-600 text-sm font-semibold whitespace-normal">
            {item.name}
          </div>
        </motion.div>
      ))}
    </div>

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 2, duration: 0.4 }}
      className="text-gray-500 text-sm mt-4 text-center space-y-1"
    >
      <p>Source: {source}</p>
      <p>Quantly results are based on the full open-source dataset containing 150 question-answer pairs.</p>
    </motion.div>
  </div>
);

const BestInClassSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-5xl font-bold text-gray-900 text-center mb-4"
        >
          Best In Class Accuracy
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-xl text-gray-600 text-center mb-12"
        >
          Accuracy scores based on FinanceBench, an industry-standard financial Q&A evaluation dataset for LLMs
        </motion.p>

        <div className="flex justify-center">
          <div className="w-[800px]">
            <AccuracyChart data={patronusData} source="Evaluation of FinanceBench by Databricks, December 2024" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestInClassSlide;
