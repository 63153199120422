import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiShieldCheck,
  HiScale,
  HiAdjustments,
  HiChartBar,
  HiLightBulb,
  HiAcademicCap,
} from "react-icons/hi";

const challenges = [
  {
    icon: HiChartBar,
    title: "Reliability and Accuracy",
    description:
      "Balancing high accuracy requirements with inherent limitations of AI models in investment research.",
  },
  {
    icon: HiLightBulb,
    title: "Trust in AI",
    description:
      "Building trust in AI-generated insights when LLMs lack clear explainability in their decision-making process.",
  },
  {
    icon: HiAcademicCap,
    title: "Learning Curve for Analysts",
    description:
      "Mastering prompt engineering and identifying optimal use cases requires significant training and adaptation.",
  },
  {
    icon: HiAdjustments,
    title: "Domain-Specific Adjustment",
    description:
      "Adapting LLMs to understand industry-specific nuances requires significant adjustments and guardrails.",
  },
  {
    icon: HiScale,
    title: "Regulatory Compliance",
    description:
      "Complex regulations around research and distribution pose challenges for LLM adoption in investment workflows.",
  },
  {
    icon: HiShieldCheck,
    title: "Data Privacy Concerns",
    description:
      "Ensuring sensitive financial data remains secure while leveraging LLM capabilities.",
  },
];

const ChallengesSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative">
        <div className="text-center mb-12">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-5xl font-bold text-gray-900 mb-4"
          >
            The Challenge to Effective Adoption of GenAI
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-gray-600"
          >
            General LLMs do not address these issues
          </motion.p>
        </div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {challenges.map((challenge, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group h-[250px]"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className="relative bg-white shadow-lg border border-gray-100 p-8 rounded-2xl hover:shadow-xl transition-all duration-300 h-full flex flex-col"
                whileHover={{ scale: 1.02 }}
              >
                <challenge.icon className="w-10 h-10 text-gray-900 mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300 flex-shrink-0" />
                <h3 className="text-xl font-semibold text-gray-900 mb-3 group-hover:translate-y-[-2px] transition-transform duration-300 flex-shrink-0">
                  {challenge.title}
                </h3>
                <p className="text-gray-600 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300 flex-grow">
                  {challenge.description}
                </p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChallengesSlide;
