import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiMicrophone,
  HiDocumentText,
  HiPresentationChartBar,
} from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const sources = [
  {
    icon: HiMicrophone,
    title: "Earnings Calls",
  },
  {
    icon: HiDocumentText,
    title: "Annual/Quarterly Reports",
  },
  {
    icon: HiPresentationChartBar,
    title: "Company Presentations",
  },
];

const DataSourcesSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Underlying Data Sources
      </motion.h2>

      <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {sources.map((source, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative group aspect-square"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
            <motion.div
              className="relative h-full bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 flex flex-col items-center justify-center"
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <source.icon className="w-16 h-16 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-2xl font-semibold text-white text-center group-hover:translate-y-[-2px] transition-transform duration-300">
                {source.title}
              </h3>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </MatrixSlideLayout>
  );
};

export default DataSourcesSlide;
