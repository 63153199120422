import { FC, useState } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const anomalyElements = [
  {
    id: "timeframe",
    text: "past two years",
    description: "Analysis period",
  },
  {
    id: "sources",
    text: "financial statements and earnings call transcripts",
    description: "Data sources",
  },
  {
    id: "analysis",
    text: "unusual fluctuations in key metrics or significant deviations from industry norms",
    description: "Anomaly detection focus",
  },
  {
    id: "context",
    text: "provide context for each anomaly detected",
    description: "Contextual analysis",
  },
];

const AnomalyDetectionSlide: FC = () => {
  const [activeElements, setActiveElements] = useState<string[]>([]);

  const toggleElement = (element: string) => {
    if (activeElements.includes(element)) {
      setActiveElements(activeElements.filter((e) => e !== element));
    } else {
      setActiveElements([...activeElements, element]);
    }
  };

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Crafting Prompts to Detect Anomalies and Outliers
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
        {/* Left Side - Concept Explanation */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <p className="text-xl mb-6">Pick up inconsistencies across time:</p>
          <ul className="space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-xl text-white/80"
            >
              • Structure prompts to identify outliers or unusual patterns in
              financial data that might signal important changes, risks, or
              opportunities.
            </motion.li>
          </ul>
        </motion.div>

        {/* Right Side - Interactive Example */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <h3 className="text-xl font-semibold mb-6">Interactive Example</h3>
          <p className="text-xl text-white/80 mb-6">
            Click elements to analyze the structure:
          </p>

          <div className="space-y-4">
            <p className="text-lg font-mono p-4 bg-black/30 rounded-xl">
              "Review Company A's{" "}
              <span
                onClick={() => toggleElement("sources")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("sources")
                    ? "text-blue-400"
                    : "text-white/80 hover:text-blue-400/80"
                }`}
              >
                financial statements and earnings call transcripts
              </span>{" "}
              from the{" "}
              <span
                onClick={() => toggleElement("timeframe")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("timeframe")
                    ? "text-purple-400"
                    : "text-white/80 hover:text-purple-400/80"
                }`}
              >
                past two years
              </span>
              . Identify any{" "}
              <span
                onClick={() => toggleElement("analysis")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("analysis")
                    ? "text-green-400"
                    : "text-white/80 hover:text-green-400/80"
                }`}
              >
                unusual fluctuations in key metrics or significant deviations
                from industry norms
              </span>
              , and{" "}
              <span
                onClick={() => toggleElement("context")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("context")
                    ? "text-yellow-400"
                    : "text-white/80 hover:text-yellow-400/80"
                }`}
              >
                provide context for each anomaly detected
              </span>
              ."
            </p>

            <div className="space-y-2">
              {anomalyElements.map(
                (part) =>
                  activeElements.includes(part.id) && (
                    <motion.div
                      key={part.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="bg-white/10 p-4 rounded-xl"
                    >
                      <span className="text-sm font-medium">
                        {part.description}:
                      </span>{" "}
                      <span className="text-white/80">{part.text}</span>
                    </motion.div>
                  ),
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default AnomalyDetectionSlide;
