import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiShieldCheck,
  HiLockClosed,
  HiCloud,
  HiCog,
  HiSupport,
  HiServer,
} from "react-icons/hi";

const sections = [
  {
    title: "Security & Compliance Excellence",
    items: [
      {
        icon: HiShieldCheck,
        title: "Secure Model Training",
        description:
          "LLM learning from inputs in a fully secure way, if model training is required",
      },
      {
        icon: HiLockClosed,
        title: "Privacy-First Approach",
        description: "You own your data and have full protection",
      },
      {
        icon: HiServer,
        title: "Industry Certifications",
        description: "SOC 2 Type II, GDPR, and CCPA compliant operations",
      },
    ],
  },
  {
    title: "Deployment & Support Framework",
    items: [
      {
        icon: HiCloud,
        title: "Flexible Implementation",
        description: "Cloud, on-premise, or hybrid solutions available",
      },
      {
        icon: HiCog,
        title: "Seamless Integration",
        description: "Custom-fitted to your existing infrastructure",
      },
      {
        icon: HiSupport,
        title: "Round-the-Clock Support",
        description: "Expert assistance available 24/7 during deployment",
      },
    ],
  },
];

const SecurityComplianceSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Security & Compliance at the Core
        </motion.h2>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="space-y-6">
              <motion.h3
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="text-2xl font-semibold text-gray-900 mb-6"
              >
                {section.title}
              </motion.h3>
              {section.items.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.3 + index * 0.1 }}
                  className="flex items-start gap-4 bg-gradient-to-br from-gray-900 to-black p-6 rounded-2xl text-white hover:scale-105 transition-all duration-300 shadow-lg group"
                >
                  <item.icon className="w-8 h-8 text-white/80 flex-shrink-0 group-hover:scale-110 transition-transform duration-300" />
                  <div>
                    <h4 className="text-lg font-semibold mb-2 group-hover:translate-y-[-2px] transition-transform duration-300">
                      {item.title}
                    </h4>
                    <p className="text-gray-300 text-sm group-hover:translate-y-[-2px] transition-transform duration-300">
                      {item.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecurityComplianceSlide;
