import { FC, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import IntroSlide from "../components/JefferiesSlides/IntroSlide";
import CurrentSituationSlide from "../components/JefferiesSlides/CurrentSituationSlide";
import GenAICapabilitiesSlide from "../components/JefferiesSlides/GenAICapabilitiesSlide";
import MarketAnalysisSlide from "../components/JefferiesSlides/MarketAnalysisSlide";
import LimitationsPotentialSlide from "../components/JefferiesSlides/LimitationsPotentialSlide";
import SecurityConcernsSlide from "../components/JefferiesSlides/SecurityConcernsSlide";
import EnterpriseDeploymentSlide from "../components/JefferiesSlides/EnterpriseDeploymentSlide";
import ProposalSlide from "../components/JefferiesSlides/ProposalSlide";
import PricingAnalysisSlide from "../components/JefferiesSlides/PricingAnalysisSlide";
import ValueCreationSlide from "../components/JefferiesSlides/ValueCreationSlide";
import WinWinPartnershipSlide from "../components/JefferiesSlides/WinWinPartnershipSlide";
import ThankYouSlide from "../components/Slides/ThankYouSlide";

const Jefferies: FC = () => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.swiper?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="h-screen w-screen bg-[#FAFAFA] overflow-hidden">
      <Swiper
        ref={swiperRef}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full"
      >
        <SwiperSlide className="bg-[#FAFAFA]">
          <IntroSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <CurrentSituationSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <GenAICapabilitiesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <MarketAnalysisSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <LimitationsPotentialSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <SecurityConcernsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <EnterpriseDeploymentSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ProposalSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PricingAnalysisSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ValueCreationSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <WinWinPartnershipSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ThankYouSlide />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Jefferies;
