import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiOutlineLightningBolt,
  HiOutlineClock,
  HiOutlineShieldCheck,
  HiOutlineChartBar,
  HiOutlineDocumentText,
  HiOutlineUserGroup,
} from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const benefits = [
  {
    icon: HiOutlineDocumentText,
    title: "Improve Research Product",
    description: "Make analysts more efficient and research higher quality",
  },
  {
    icon: HiOutlineUserGroup,
    title: "More Time for Calls",
    description: "Let analysts move to higher value-add tasks",
  },
  {
    icon: HiOutlineLightningBolt,
    title: "Leader in AI Adoption",
    description: "First mover in adopting AI in research",
  },
  {
    icon: HiOutlineClock,
    title: "Future-Ready",
    description: "Transform research with AI, ready for the future",
  },
  {
    icon: HiOutlineShieldCheck,
    title: "Enterprise Security",
    description: "Complete security & regulatory compliance",
  },
  {
    icon: HiOutlineChartBar,
    title: "Influence on Roadmap",
    description: "As our anchor client, we will develop for your use cases",
  },
];

const ValueCreationSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.div className="space-y-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          Value for Jefferies
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 + index * 0.1 }}
              className="bg-gradient-to-br from-gray-900/90 to-black/90 p-8 rounded-2xl border border-white/10 backdrop-blur-sm group hover:scale-105 transition-all duration-300"
            >
              <div className="flex flex-col items-start gap-6">
                <div className="w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-2xl flex items-center justify-center transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300">
                  <benefit.icon className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-white mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
                    {benefit.title}
                  </h3>
                  <p className="text-white/80 text-lg leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                    {benefit.description}
                  </p>
                </div>
              </div>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </MatrixSlideLayout>
  );
};

export default ValueCreationSlide;
