import { FC, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import IntroductionSlide from "../components/GuideSlides/IntroductionSlide";
import FeaturesSlide from "../components/GuideSlides/FeaturesSlide";
import UsageSlide from "../components/GuideSlides/UsageSlide";
import ChallengesSlide from "../components/Slides/ChallengesSlide";
import AnalystTodaySlide from "../components/Slides/AnalystTodaySlide";
import AIAugmentedAnalystSlide from "../components/Slides/AIAugmentedAnalystSlide";
import ImpactSlide from "../components/GuideSlides/ImpactSlide";
import RecentResearchSlide from "../components/GuideSlides/RecentResearchSlide";
import WarningSlide from "../components/GuideSlides/WarningSlide";
import PromptingIntroSlide from "../components/GuideSlides/PromptingIntroSlide";
import PromptDefinitionSlide from "../components/GuideSlides/PromptDefinitionSlide";
import PromptQualitySlide from "../components/GuideSlides/PromptQualitySlide";
import PromptingPrinciplesSlide from "../components/GuideSlides/PromptingPrinciplesSlide";
import PromptComparisonSlide from "../components/GuideSlides/PromptComparisonSlide";
import PromptStructureSlide from "../components/GuideSlides/PromptStructureSlide";
import LeadingQuestionsSlide from "../components/GuideSlides/LeadingQuestionsSlide";
import IterativePromptingSlide from "../components/GuideSlides/IterativePromptingSlide";
import PromptChainingSlide from "../components/GuideSlides/PromptChainingSlide";
import CommonPitfallsSlide from "../components/GuideSlides/CommonPitfallsSlide";
import AdvancedPromptingIntroSlide from "../components/GuideSlides/AdvancedPromptingIntroSlide";
import HypothesisDrivenPromptSlide from "../components/GuideSlides/HypothesisDrivenPromptSlide";
import TemporalAnalysisSlide from "../components/GuideSlides/TemporalAnalysisSlide";
import ComparativePromptsSlide from "../components/GuideSlides/ComparativePromptsSlide";
import AnomalyDetectionSlide from "../components/GuideSlides/AnomalyDetectionSlide";
import TasksSuitedSlide from "../components/GuideSlides/TasksSuitedSlide";
import CombiningInsightsSlide from "../components/GuideSlides/CombiningInsightsSlide";
import ImportantMessageSlide from "../components/GuideSlides/ImportantMessageSlide";
import IntegrationProcessSlide from "../components/GuideSlides/IntegrationProcessSlide";
import AdaptingRoleSlide from "../components/GuideSlides/AdaptingRoleSlide";
import FinalCallToActionSlide from "../components/GuideSlides/FinalCallToActionSlide";
import SecurityComplianceSlide from "../components/Slides/SecurityComplianceSlide";
// import TestimonialsSlide from "../components/Slides/TestimonialsSlide";

const Guide: FC = () => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.swiper?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="h-screen w-screen bg-[#FAFAFA] overflow-hidden">
      <Swiper
        ref={swiperRef}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full"
      >
        <SwiperSlide className="bg-[#FAFAFA]">
          <IntroductionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <FeaturesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <UsageSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ImpactSlide />
        </SwiperSlide>{" "}
        <SwiperSlide className="bg-[#FAFAFA]">
          <AnalystTodaySlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AIAugmentedAnalystSlide />
        </SwiperSlide>{" "}
        {/* <SwiperSlide className="bg-[#FAFAFA]">
          <SecurityComplianceSlide />        </SwiperSlide> */}
        <SwiperSlide className="bg-[#FAFAFA]">
          <ChallengesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <RecentResearchSlide />
        </SwiperSlide>{" "}
        {/* <SwiperSlide className="bg-[#FAFAFA]">
          <TestimonialsSlide />
        </SwiperSlide> */}
        <SwiperSlide className="bg-[#FAFAFA]">
          <WarningSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptingIntroSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptDefinitionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptQualitySlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptingPrinciplesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptComparisonSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptStructureSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <LeadingQuestionsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <IterativePromptingSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <PromptChainingSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <CommonPitfallsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AdvancedPromptingIntroSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <HypothesisDrivenPromptSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <TemporalAnalysisSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ComparativePromptsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AnomalyDetectionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <TasksSuitedSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <CombiningInsightsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ImportantMessageSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <IntegrationProcessSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AdaptingRoleSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <FinalCallToActionSlide />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Guide;
