import { FC, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

interface PromptStep {
  id: number;
  prompt: string;
  response: string;
  followUps: string[];
}

const promptSteps: PromptStep[] = [
  {
    id: 1,
    prompt: "Summarize Company A's competitive position",
    response:
      "Company A holds a strong market position with 25% market share, industry-leading profit margins, and established distribution networks across key regions.",
    followUps: [
      "What potential threats to this position are evident from recent industry trends?",
      "How does their market share compare to the top 3 competitors?",
      "Which regions show the strongest growth potential?",
    ],
  },
  {
    id: 2,
    prompt:
      "What potential threats to this position are evident from recent industry trends?",
    response:
      "Emerging digital-first competitors are disrupting traditional distribution channels, while changing consumer preferences towards sustainable products pose adaptation challenges.",
    followUps: [
      "How is Company A addressing the shift to digital distribution?",
      "What sustainability initiatives has Company A launched?",
      "Which competitors are leading the sustainability transition?",
    ],
  },
];

const IterativePromptingSlide: FC = () => {
  const [expandedStep, setExpandedStep] = useState<number | null>(null);

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Iterative Prompting
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-start">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white"
        >
          <p className="text-xl mb-6">
            Use a series of prompts to progressively refine and deepen the
            analysis. Start with broader queries and then ask follow-up
            questions based on initial responses.
          </p>
          <p className="text-xl text-white/80">
            This approach allows for more thorough and nuanced insights.
          </p>
        </motion.div>

        <div className="relative">
          <div className="absolute left-8 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500/50 via-purple-500/50 to-blue-500/50" />

          <div className="space-y-8">
            {promptSteps.map((step, index) => (
              <motion.div
                key={step.id}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="relative pl-16"
              >
                <motion.div
                  className="w-4 h-4 rounded-full bg-blue-500 absolute left-6 top-4 -translate-x-1/2"
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [1, 0.8, 1],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                />

                <div
                  onClick={() =>
                    setExpandedStep(expandedStep === step.id ? null : step.id)
                  }
                  className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl text-white cursor-pointer hover:scale-[1.02] transition-all duration-300"
                >
                  <p className="text-lg font-mono mb-4">{step.prompt}</p>

                  <AnimatePresence>
                    {expandedStep === step.id && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <div className="border-t border-white/10 pt-4 mt-4">
                          <p className="text-white/80 mb-4">{step.response}</p>
                          <div className="space-y-2">
                            <p className="text-sm text-white/60 mb-2">
                              Follow-up questions:
                            </p>
                            {step.followUps.map((followUp, idx) => (
                              <p
                                key={idx}
                                className="text-white/80 pl-4 border-l-2 border-purple-500"
                              >
                                {followUp}
                              </p>
                            ))}
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default IterativePromptingSlide;
