import { FC } from "react";
import {
  HiLightBulb,
  HiPuzzle,
  HiSparkles,
  HiSearch,
  HiViewGrid,
  HiScale,
} from "react-icons/hi";

const insights = [
  {
    icon: HiLightBulb,
    title: "AI as a Starting Point",
    description:
      "Using AI insights as an initial guide to diving into your research, setting a foundation for further exploration and analysis",
  },
  {
    icon: HiPuzzle,
    title: "Contextualizing AI Findings",
    description:
      "Integrate your expert analyst knowledge and personal insights to interpret AI-generated results, to boost your overall research capacity and quality",
  },
  {
    icon: HiSparkles,
    title: "Getting Creative",
    description:
      "AI allows you to experiment and ask questions that you wouldn't usually ask. Why? It costs a few seconds to get to the answer rather than hours/days",
  },
  {
    icon: HiSearch,
    title: "Identifying Alignments and Discrepancies",
    description:
      "Spot where AI findings align with or diverge from human expectations, leading to deeper investigation",
  },
  {
    icon: HiViewGrid,
    title: "Broadening Perspectives with AI",
    description:
      "Leverage AI to gain new viewpoints, enhancing the scope of traditional analysis and identifying overlooked aspects",
  },
  {
    icon: HiScale,
    title: "Applying Critical Thinking",
    description:
      "Employ critical thinking to assess AI recommendations, refining and validating results to ensure accuracy and reliability",
  },
];

const CombiningInsightsSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800" />
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Combining AI-driven Insights with Human Intuition and Experience
        </h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
          {insights.map((insight, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl border border-white/10 backdrop-blur-sm min-h-[280px] flex flex-col"
            >
              <insight.icon className="w-12 h-12 mb-4 text-white" />
              <h3 className="text-2xl font-semibold mb-4 text-white">
                {insight.title}
              </h3>
              <p className="text-white/80 text-lg">{insight.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CombiningInsightsSlide;
