import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Navigation: FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const showNavigation = !isScrolled && (location.pathname === "/" || location.pathname === "/api");

  return (
    <motion.nav
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="fixed top-8 right-8 z-50 flex gap-4 transition-opacity duration-300"
    >
      {showNavigation &&
        ["API", "Blog"].map((item, index) => (
          <a
            key={item}
            href={item === "Blog" ? "https://quantly.substack.com/" : "/api"}
            className="group relative px-6 py-2 transition-colors duration-200"
          >
            <span className="relative z-10 text-gray-900 font-medium tracking-wide transition-colors duration-200 group-hover:text-white pointer-events-none">
              {item}
            </span>
            <motion.div
              className="absolute inset-0 bg-black rounded-xl -z-0"
              initial={{ scale: 0.95, opacity: 0 }}
              whileHover={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2 }}
            />
          </a>
        ))}
    </motion.nav>
  );
};

export default Navigation;
