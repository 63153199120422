import { FC } from "react";
import { motion } from "framer-motion";
import { HiGlobe, HiClock, HiTemplate } from "react-icons/hi";

const features = [
  {
    icon: HiGlobe,
    title: "Cross-Market Coverage",
    description: "Build datasets across companies, industries, and markets",
  },
  {
    icon: HiClock,
    title: "Historical Data",
    description: "Access and structure historical data points over time",
  },
  {
    icon: HiTemplate,
    title: "Custom Schemas",
    description: "Define custom data schemas based on your requirements",
  },
];

const BespokeDatasetSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 bg-gradient-to-br from-gray-900 via-black to-gray-900">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-white mb-12"
        >
          Bespoke Dataset Creation
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white hover:scale-[1.02] transition-all duration-500 group border border-white/10 backdrop-blur-sm text-center relative overflow-hidden h-full"
            >
              <feature.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                {feature.title}
              </h3>
              <p className="text-white/80 text-lg group-hover:translate-y-[-2px] transition-transform duration-300">
                {feature.description}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                initial={false}
                style={{
                  backgroundSize: "200% 100%",
                }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BespokeDatasetSlide;
