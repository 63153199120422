import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDatabase,
  HiDocumentText,
  HiTable,
  HiCloud,
  HiGlobeAlt,
  HiLightningBolt,
  HiCode,
  HiCog,
  HiCheckCircle,
  HiTemplate,
  HiUserGroup,
} from "react-icons/hi";

const QuantlyTodaySlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-5xl font-bold text-gray-900 mb-24 text-center"
        >
          What Quantly Can Do Today
        </motion.h2>

        {/* Architecture Diagram */}
        <div className="relative h-[600px] mx-auto w-full max-w-[2000px]">
          {/* Layer Labels */}
          <div className="absolute top-0 w-full flex justify-between text-sm font-semibold text-gray-600 px-8 py-2 border-b border-gray-200 bg-white/50">
            <span className="w-1/4 text-center">Data Sources</span>
            <span className="w-1/4 text-center">Processing Engine</span>
            <span className="w-1/4 text-center">Output Interfaces</span>
            <span className="w-1/4 text-center">User Groups</span>
          </div>

          {/* Main Content Columns */}
          <div className="relative flex h-full pt-16">
            {/* Data Sources Column */}
            <div className="flex-1 flex flex-col items-center border-r border-gray-200 px-6">
              {/* Internal Data */}
              <motion.div 
                className="w-full bg-white shadow-lg border border-gray-100 p-6 rounded-xl mb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h4 className="text-gray-900 font-medium mb-4">Internal Data</h4>
                <div className="space-y-3">
                  {[
                    { icon: HiDocumentText, text: "PDFs & Documents" },
                    { icon: HiTable, text: "Excel Files" },
                    { icon: HiDatabase, text: "Databases" },
                    { icon: HiCloud, text: "API Data Streams" },
                  ].map((item, i) => (
                    <div key={i} className="flex items-center gap-3 text-gray-600">
                      <item.icon className="w-5 h-5 text-gray-900" />
                      <span>{item.text}</span>
                    </div>
                  ))}
                </div>
              </motion.div>

              {/* External Data */}
              <motion.div 
                className="w-full bg-white shadow-lg border border-gray-100 p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <h4 className="text-gray-900 font-medium mb-4">External Data</h4>
                <div className="flex items-center gap-3 text-gray-600">
                  <HiGlobeAlt className="w-5 h-5 text-gray-900" />
                  <span>3rd Party Data Providers</span>
                </div>
              </motion.div>
            </div>

            {/* Processing Column */}
            <div className="flex-1 flex flex-col items-center px-6">
              {/* Features Grid */}
              <div className="grid grid-cols-2 gap-3 mb-8 w-full">
                {[
                  { icon: HiLightningBolt, text: "Specialised LLM Agents" },
                  { icon: HiCode, text: "Data Analysis" },
                  { icon: HiCog, text: "Automatic Prompt Improvement" },
                  { icon: HiCheckCircle, text: "Source Attribution" },
                ].map((feature, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 + i * 0.1 }}
                    className="bg-white shadow-lg border border-gray-100 p-4 rounded-xl"
                  >
                    <div className="flex items-center gap-3">
                      <feature.icon className="w-5 h-5 text-gray-900" />
                      <span className="text-sm font-medium text-gray-900">{feature.text}</span>
                    </div>
                  </motion.div>
                ))}
              </div>

              {/* Engine */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="w-[300px] bg-black p-6 rounded-xl shadow-xl"
              >
                <h3 className="text-lg font-bold text-white text-center">
                  Quantly Reasoning Engine
                </h3>
              </motion.div>
            </div>

            {/* Output Column */}
            <div className="flex-1 flex flex-col items-center border-l border-gray-200 px-6">
              {[
                { icon: HiTemplate, title: "Augmented Workflows" },
                { icon: HiLightningBolt, title: "AI Analyst Assistant" },
              ].map((output, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.9 + i * 0.1 }}
                  className="w-full bg-white shadow-lg border border-gray-100 p-6 rounded-xl mb-6 last:mb-0"
                >
                  <div className="flex items-center gap-3">
                    <output.icon className="w-6 h-6 text-gray-900" />
                    <h4 className="text-gray-900 font-medium">{output.title}</h4>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* User Groups Column */}
            <div className="flex-1 flex flex-col items-center border-l border-gray-200 px-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9 }}
                className="w-full bg-white shadow-lg border border-gray-100 p-6 rounded-xl"
              >
                <div className="flex items-center gap-3">
                  <HiUserGroup className="w-6 h-6 text-gray-900" />
                  <h4 className="text-gray-900 font-medium">Research</h4>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantlyTodaySlide; 