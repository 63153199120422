import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const PromptingIntroSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="flex flex-col items-center justify-center min-h-[80vh]">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          src="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
          alt="Quantly Logo"
          className="desktop:w-48 laptop:w-48 tablet:w-40 mobile:w-32 h-auto mb-12"
        />
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-6xl font-bold text-gray-900 mb-8 text-center"
        >
          Fundamentals of Effective Prompting
        </motion.h2>
      </div>
    </MatrixSlideLayout>
  );
};

export default PromptingIntroSlide;
