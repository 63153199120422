import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";

const AlertBanner: FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsVisible(currentScrollPos < 100);
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [location]);

  if (!isVisible || location.pathname !== "/") return null;
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.8 }}
      className="fixed desktop:top-8 laptop:top-8 tablet:top-6 mobile:top-6 w-full z-40 transform-gpu"
    >
      <a
        href="https://docsend.com/v/qphq8/genai_guide"
        target="_blank"
        rel="noopener noreferrer"
        className="desktop:max-w-3xl laptop:max-w-2xl tablet:max-w-xl mobile:max-w-xs mx-auto flex items-center justify-center gap-2 bg-black text-white px-6 py-3 rounded-3xl transform-gpu hover:scale-105 transition-transform duration-300"
      >
        <span className="text-base font-medium">
          Check out our Guide to Prompting for Investment Research
        </span>
        <HiArrowRight className="w-4 h-4 transform-gpu group-hover:translate-x-1 transition-transform duration-300" />
      </a>
    </motion.div>
  );
};

export default AlertBanner;
