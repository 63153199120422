import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiOutlineUserGroup,
  HiOutlineLightningBolt,
  HiOutlineTemplate,
  HiOutlineChip,
  HiUserGroup,
} from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const benefits = [
  {
    icon: HiOutlineUserGroup,
    title: "Enable All Analysts",
    description: "Universal access to AI capabilities",
  },
  {
    icon: HiOutlineLightningBolt,
    title: "Competitive Advantage",
    description: "Early adoption leadership position",
  },
  {
    icon: HiUserGroup,
    title: "Culture of Innovation",
    description: "Daily GenAI usage enhances adoption",
  },
  {
    icon: HiOutlineChip,
    title: "Transform Infrastructure",
    description: "Modernize entire research platform",
  },
  {
    icon: HiOutlineTemplate,
    title: "Future Foundation",
    description: "Platform for deeper integration",
  },
];

const EnterpriseDeploymentSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Why Enterprise-Wide Deployment Makes Sense
      </motion.h2>

      <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl text-white hover:scale-105 transition-all duration-500 flex-1 group overflow-hidden"
          >
            <benefit.icon className="w-10 h-10 mb-6 text-white/80 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
            <h3 className="text-xl font-semibold mb-4 group-hover:translate-y-[-2px] transition-transform duration-300">
              {benefit.title}
            </h3>
            <p className="text-white/80 group-hover:translate-y-[-2px] transition-transform duration-300">
              {benefit.description}
            </p>
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
              initial={{ x: "-200%" }}
              animate={{ x: "200%" }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
              }}
            />
          </motion.div>
        ))}
      </div>
    </MatrixSlideLayout>
  );
};

export default EnterpriseDeploymentSlide;
