import { FC } from "react";
import DesignOne from "./DesignOne";
import DesignTwo from "./DesignTwo";
import DesignThree from "./DesignThree";

// You can uncomment and use this to let the customer switch between designs
// during development. For production, you would just export the chosen design directly.

const AdaptingRoleSlide: FC = () => {
  // For now, we'll just return DesignOne as the default
  return <DesignThree />; // Using DesignThree with updated styling
};

export default AdaptingRoleSlide;
