import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "./layouts/MatrixSlideLayout";

const DemoSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="text-center">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-6xl font-bold text-gray-900 mb-12"
        >
          See Quantly in Action
        </motion.h2>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="relative desktop:w-[800px] laptop:w-[800px] tablet:w-full mobile:w-full mx-auto rounded-2xl overflow-hidden shadow-xl"
        >
          <div
            className="relative"
            style={{ paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src="https://www.loom.com/embed/7b5228b781304b4daa5131e30b0133d9?sid=c75f76b6-ebdc-430e-9426-7f6ad8c27ff6"
              frameBorder="0"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full rounded-2xl"
            />
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default DemoSlide;
