import { FC, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiChevronDown } from "react-icons/hi";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      className="bg-[#FAFAFA] rounded-2xl overflow-hidden"
      style={{
        willChange: 'transform',
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden'
      }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-6 text-left"
      >
        <span className="text-gray-900 font-semibold">
          {question}
        </span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ 
            duration: 0.2,
            ease: [0.32, 0.72, 0, 1]
          }}
          style={{ 
            willChange: 'transform',
            transform: 'translateZ(0)'
          }}
        >
          <HiChevronDown className="w-5 h-5 text-gray-500" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            ref={contentRef}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { 
                height: 'auto',
                opacity: 1,
                transition: {
                  height: { 
                    duration: 0.3,
                    ease: [0.32, 0.72, 0, 1]
                  },
                  opacity: { 
                    duration: 0.15,
                    ease: 'linear'
                  }
                }
              },
              collapsed: { 
                height: 0,
                opacity: 0,
                transition: {
                  height: { 
                    duration: 0.3,
                    ease: [0.32, 0.72, 0, 1]
                  },
                  opacity: { 
                    duration: 0.1,
                    ease: 'linear'
                  }
                }
              }
            }}
            style={{ 
              overflow: 'hidden',
              willChange: 'height, opacity',
              transform: 'translateZ(0)'
            }}
          >
            <div className="px-6 pb-6">
              <motion.p 
                className="text-gray-600"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{
                  duration: 0.2,
                  ease: [0.32, 0.72, 0, 1]
                }}
              >
                {answer}
              </motion.p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FAQItem;
