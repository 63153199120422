import { FC, useState } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const comparativeElements = [
  {
    id: "scope",
    text: "latest annual reports of the top three companies in the electric vehicle sector",
    description: "Analysis scope",
  },
  {
    id: "metric1",
    text: "R&D spending",
    description: "Comparison metric 1",
  },
  {
    id: "metric2",
    text: "production scalability",
    description: "Comparison metric 2",
  },
  {
    id: "metric3",
    text: "market expansion strategies",
    description: "Comparison metric 3",
  },
];

const ComparativePromptsSlide: FC = () => {
  const [activeElements, setActiveElements] = useState<string[]>([]);

  const toggleElement = (element: string) => {
    if (activeElements.includes(element)) {
      setActiveElements(activeElements.filter((e) => e !== element));
    } else {
      setActiveElements([...activeElements, element]);
    }
  };

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Structuring Comparative Prompts for Cross-entity Insights
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
        {/* Left Side - Concept Explanation */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <p className="text-xl mb-6">Compare information across companies:</p>
          <ul className="space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-xl text-white/80"
            >
              • Craft prompts that analyze multiple entities (companies,
              sectors, etc.) simultaneously to gain comparative insights and
              identify relative strengths or weaknesses.
            </motion.li>
          </ul>
        </motion.div>

        {/* Right Side - Interactive Example */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <h3 className="text-xl font-semibold mb-6">Interactive Example</h3>
          <p className="text-xl text-white/80 mb-6">
            Click elements to analyze the structure:
          </p>

          <div className="space-y-4">
            <p className="text-lg font-mono p-4 bg-black/30 rounded-xl">
              "Analyze the{" "}
              <span
                onClick={() => toggleElement("scope")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("scope")
                    ? "text-blue-400"
                    : "text-white/80 hover:text-blue-400/80"
                }`}
              >
                latest annual reports of the top three companies in the electric
                vehicle sector
              </span>
              . Compare their{" "}
              <span
                onClick={() => toggleElement("metric1")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("metric1")
                    ? "text-purple-400"
                    : "text-white/80 hover:text-purple-400/80"
                }`}
              >
                R&D spending
              </span>
              ,{" "}
              <span
                onClick={() => toggleElement("metric2")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("metric2")
                    ? "text-green-400"
                    : "text-white/80 hover:text-green-400/80"
                }`}
              >
                production scalability
              </span>
              , and{" "}
              <span
                onClick={() => toggleElement("metric3")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("metric3")
                    ? "text-yellow-400"
                    : "text-white/80 hover:text-yellow-400/80"
                }`}
              >
                market expansion strategies
              </span>
              ."
            </p>

            <div className="space-y-2">
              {comparativeElements.map(
                (part) =>
                  activeElements.includes(part.id) && (
                    <motion.div
                      key={part.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="bg-white/10 p-4 rounded-xl"
                    >
                      <span className="text-sm font-medium">
                        {part.description}:
                      </span>{" "}
                      <span className="text-white/80">{part.text}</span>
                    </motion.div>
                  ),
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default ComparativePromptsSlide;
