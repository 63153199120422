import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiTemplate,
  HiCode,
  HiGlobeAlt,
} from "react-icons/hi";

const DeliveryOptionsSlide: FC = () => {
  const options = [
    {
      icon: HiTemplate,
      title: "Standalone Web App",
      description: "Access Quantly through our web app and start using it right away",
    },
    {
      icon: HiCode,
      title: "API",
      description: "Integrate Quantly directly into your systems",
    },
    {
      icon: HiGlobeAlt,
      title: "Within The Apps You Already Use",
      description: "Use Quantly through Microsoft Teams and more",
    }
  ];

  return (
    <div className="w-full flex items-center justify-center py-16">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <motion.div className="text-center mb-12">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-gray-900"
          >
            We Deliver Quantly In Your Preferred Way
          </motion.h2>
        </motion.div>

        {/* Delivery Options */}
        <div className="flex justify-center items-stretch gap-6">
          {options.map((option, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 + i * 0.1 }}
              className="w-[280px] bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl border border-gray-100 transition-all duration-300 flex flex-col"
              whileHover={{ scale: 1.02 }}
            >
              <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center mb-4">
                <option.icon className="w-6 h-6 text-gray-900" />
              </div>
              <h3 className="text-lg font-bold text-gray-900 mb-2">
                {option.title}
              </h3>
              <p className="text-gray-600 text-sm">
                {option.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptionsSlide; 