import { FC } from "react";
import { motion } from "framer-motion";

interface SavingsBannerProps {
  standardPrice: number;
  enterprisePrice: number;
  analysts: number;
  fixedSavings?: number;
}

const SavingsBanner: FC<SavingsBannerProps> = ({
  standardPrice,
  enterprisePrice,
  analysts,
  fixedSavings,
}) => {
  const annualSavings =
    fixedSavings || (standardPrice - enterprisePrice) * analysts;
  const formattedSavings = annualSavings.toLocaleString();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.4 }}
      className="relative group"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 via-blue-500/20 to-purple-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl" />

      <motion.div
        className="relative bg-gradient-to-br from-gray-900/90 to-black/90 p-8 rounded-2xl border border-white/10 backdrop-blur-sm text-center"
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-xl text-white/80 mb-2">Annual Savings</h3>
        <div className="text-4xl font-bold text-white mb-2">
          ${formattedSavings}
        </div>
        <p className="text-white/60">Based on {analysts} analysts</p>

        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
          initial={{ x: "-200%" }}
          animate={{ x: "200%" }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
            ease: "linear",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default SavingsBanner;
