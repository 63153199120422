import { FC, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import DataIntroSlide from "../components/DataSlides/DataIntroSlide";
import WhoWeAreSlide from "../components/Slides/WhoWeAreSlide";
import DataCapabilitiesSlide from "../components/DataSlides/DataCapabilitiesSlide";
import ThinkAnyQuestionSlide from "../components/DataSlides/ThinkAnyQuestionSlide";
import DataExtractionSlide from "../components/DataSlides/DataExtractionSlide";
import DataSourcesSlide from "../components/DataSlides/DataSourcesSlide";
import DeliveryMethodSlide from "../components/DataSlides/DeliveryMethodSlide";
import BespokeDatasetSlide from "../components/DataSlides/BespokeDatasetSlide";
import ThankYouSlide from "../components/Slides/ThankYouSlide";

const Data: FC = () => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.swiper?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);
  return (
    <div className="h-screen w-screen bg-[#FAFAFA] overflow-hidden">
      <Swiper
        ref={swiperRef}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full"
      >
        <SwiperSlide className="bg-[#FAFAFA]">
          <DataIntroSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <WhoWeAreSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DataCapabilitiesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ThinkAnyQuestionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DataExtractionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DeliveryMethodSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DataSourcesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <BespokeDatasetSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ThankYouSlide />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Data;
