import { FC, useCallback } from "react";
import { motion } from "framer-motion";
import { HiDownload } from "react-icons/hi";
import { useThree } from "@react-three/fiber";

const ExportPDFButton: FC = () => {
  const handleExport = useCallback(async () => {
    // Add print-specific styles
    const style = document.createElement("style");
    style.textContent = `
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
        .swiper-slide {
          break-inside: avoid;
          page-break-after: always;
          height: 100vh;
          width: 100vw;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .swiper-button-next,
        .swiper-button-prev,
        .swiper-pagination,
        button[class*="ExportPDFButton"] {
          display: none !important;
        }
        canvas {
          display: none !important;
        }
        * {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
      }
    `;
    document.head.appendChild(style);

    // Set print options
    const printOptions = {
      margin: 0,
      filename: "quantly-presentation.pdf",
      pagebreak: { mode: "avoid-all" },
      landscape: true,
    };

    // Trigger print dialog
    window.print();

    // Clean up print styles after printing
    document.head.removeChild(style);
  }, []);

  return (
    <motion.button
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      onClick={handleExport}
      className="ExportPDFButton-root fixed bottom-8 right-8 z-50 bg-black text-white rounded-full px-6 py-3 flex items-center gap-2 hover:scale-105 transition-transform duration-300 shadow-lg print:hidden"
    >
      <HiDownload className="w-5 h-5" />
      <span>Export PDF</span>
    </motion.button>
  );
};

export default ExportPDFButton;
