import { FC } from "react";
import { motion } from "framer-motion";
import MatrixBackground from "../Slides/components/ParticlesBackground";

// Design Option 1: Large Text with Spacious Layout
const LargeTextCard: FC<CardProps> = ({ title, content, delay }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6, delay }}
    className="relative group"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl" />
    <div className="min-h-[320px] bg-gradient-to-br from-gray-900 via-black to-gray-800 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 relative overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
        initial={{ x: "-200%" }}
        animate={{ x: "200%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          ease: "linear",
        }}
      />
      <div className="relative z-10 h-full flex flex-col">
        <h3 className="text-3xl font-semibold text-white mb-6 group-hover:translate-y-[-2px] transition-transform duration-300">
          {title}
        </h3>
        <p className="text-white/80 text-xl leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
          {content}
        </p>
      </div>
    </div>
  </motion.div>
);

// Design Option 2: Bold Headlines with Balanced Body Text
const BoldHeadlineCard: FC<CardProps> = ({ title, content, delay }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6, delay }}
    className="relative group"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl" />
    <div className="min-h-[320px] bg-gradient-to-br from-gray-900 via-black to-gray-800 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 relative overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
        initial={{ x: "-200%" }}
        animate={{ x: "200%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          ease: "linear",
        }}
      />
      <div className="relative z-10 h-full flex flex-col">
        <h3 className="text-4xl font-bold text-white mb-4 group-hover:translate-y-[-2px] transition-transform duration-300">
          {title}
        </h3>
        <p className="text-white/90 text-lg leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
          {content}
        </p>
      </div>
    </div>
  </motion.div>
);

// Design Option 3: Balanced Text with Enhanced Contrast
const BalancedTextCard: FC<CardProps> = ({ title, content, delay }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6, delay }}
    className="relative group"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl" />
    <div className="min-h-[320px] bg-gradient-to-br from-gray-900 via-black to-gray-800 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 relative overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
        initial={{ x: "-200%" }}
        animate={{ x: "200%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          ease: "linear",
        }}
      />
      <div className="relative z-10 h-full flex flex-col">
        <h3 className="text-2xl font-bold text-white mb-5 group-hover:translate-y-[-2px] transition-transform duration-300">
          {title}
        </h3>
        <p className="text-white/95 text-xl leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
          {content}
        </p>
      </div>
    </div>
  </motion.div>
);

interface CardProps {
  title: string;
  content: string;
  delay: number;
}

const UsageSlide: FC = () => {
  const points = [
    {
      title: "Growing Adoption",
      content:
        "GenAI is increasingly being adopted in investment research for tasks like information retrieval, idea generation, data analysis and report generation.",
    },
    {
      title: "Competitive Landscape",
      content:
        "Major financial institutions, large data providers and AI-native startups are building in the space and competing for analysts' ever fragmented attention.",
    },
    {
      title: "Lack of Expertise",
      content:
        "There is interest to build internally, but few financial institutions have the resources and expertise. They are looking to partner with external providers.",
    },
  ];

  // Change this line to switch between design options:
  // const CardComponent = LargeTextCard;
  // const CardComponent = BoldHeadlineCard;
  const CardComponent = BalancedTextCard;

  return (
    <div className="h-full w-full flex items-center justify-center p-12 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <MatrixBackground />
      </div>
      <div className="relative z-10 desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.div className="text-center mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="desktop:text-5xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-white mb-4"
          >
            GenAI in Finance and Investment Research
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="desktop:text-2xl laptop:text-2xl tablet:text-xl mobile:text-lg text-gray-400"
          >
            Current Landscape of GenAI in Finance
          </motion.p>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {points.map((point, index) => (
            <CardComponent
              key={index}
              title={point.title}
              content={point.content}
              delay={0.4 + index * 0.2}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsageSlide;
