import { motion } from "framer-motion";
import { FC } from "react";

const MainHeadline: FC = () => (
  <motion.h1
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.4 }}
    className="font-bold text-gray-900 desktop:text-[4.5rem] laptop:text-[4rem] tablet:text-5xl mobile:text-4xl desktop:leading-[1.05] laptop:leading-[1.05] tablet:leading-tight mobile:leading-tight mb-8"
  >
    LLM Infrastructure
    <br />
    <span className="relative inline-block text-gray-400">
      for Investment
      <motion.div
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
        className="absolute bottom-[0.15em] left-0 h-[0.15em] bg-[#E8E8E8] -z-10 origin-left"
      />
    </span>
    <br />
    <span className="text-gray-400">Research</span>
  </motion.h1>
);

export default MainHeadline;
