import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";
import ProposalPricingCard from "./components/ProposalPricingCard";
import EnterpriseBenefits from "./components/EnterpriseBenefits";
import SavingsBanner from "./components/SavingsBanner";

const ProposalSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        The Proposal
      </motion.h2>

      <div className="space-y-8">
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {/* Left Column - Pricing Comparison */}
          <div className="space-y-6">
            <ProposalPricingCard
              title="Current Proposal"
              price={3000}
              description="Per seat, per year"
              isStandard={true}
            />
            <ProposalPricingCard
              title="Enterprise License"
              price={40000}
              description="Total annual fee for all European & UK analysts"
              isStandard={false}
            />
          </div>

          {/* Right Column - Enterprise Benefits */}
          <EnterpriseBenefits />
        </div>

        {/* Bottom Banner - Annual Savings */}
        <SavingsBanner
          standardPrice={3000}
          enterprisePrice={40000}
          analysts={50}
          fixedSavings={110000}
        />
      </div>
    </MatrixSlideLayout>
  );
};

export default ProposalSlide;
