import { FC } from "react";
import { motion } from "framer-motion";
import { HiTemplate, HiClock, HiRefresh } from "react-icons/hi";
import MatrixSlideLayout from "../../components/ProductSlides/layouts/MatrixSlideLayout";

const features = [
  {
    icon: HiTemplate,
    title: "Create Custom Workflows",
    description:
      "Design and save your research processes as reusable playbooks",
  },
  {
    icon: HiClock,
    title: "Save Time",
    description:
      "Transform hours of manual work into automated processes that run in seconds",
  },
  {
    icon: HiRefresh,
    title: "Consistent Execution",
    description:
      "Ensure research consistency with standardized, repeatable workflows",
  },
];

const QuantlyPlaybooksSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="space-y-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          <h2 className="text-4xl font-bold text-gray-900">
            Quantly Playbooks
          </h2>
        </motion.div>

        <div className="flex flex-col desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-8">
          <div className="desktop:w-1/3 laptop:w-1/3 tablet:w-full mobile:w-full space-y-6">
            {features.map((feature, index) => (
              <motion.div key={index} className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
                <motion.div
                  className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500"
                  whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
                >
                  <feature.icon className="w-8 h-8 mb-4 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                  <h3 className="text-xl font-semibold mb-2 text-white">
                    {feature.title}
                  </h3>
                  <p className="text-white/80">{feature.description}</p>
                  <motion.div
                    className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                    initial={{ x: "-200%" }}
                    animate={{ x: "200%" }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "loop",
                      ease: "linear",
                    }}
                  />
                </motion.div>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="desktop:w-2/3 laptop:w-2/3 tablet:w-full mobile:w-full bg-white rounded-2xl overflow-hidden shadow-xl h-fit"
          >
            <img
              src="https://presentations.quantly-ai.com/Screenshot%202025-01-16%20at%2012.35.40.png"
              alt="Quantly Playbooks Platform"
              className="w-full block"
            />
          </motion.div>
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default QuantlyPlaybooksSlide;
