import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiScale, HiSparkles } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const benefits = [
  {
    icon: HiLightningBolt,
    title: "First Mover into Banks",
    description:
      "Pioneer the integration of enterprise-grade AI in investment banking research",
    gradient: "from-cyan-500/90 to-blue-500/90",
  },
  {
    icon: HiScale,
    title: "Feedback at Scale",
    description:
      "Gain invaluable insights from real-world usage to improve our product",
    gradient: "from-emerald-500/90 to-teal-500/90",
  },
  {
    icon: HiSparkles,
    title: "Industry Transformation",
    description:
      "Partner in revolutionizing how investment research is conducted",
    gradient: "from-violet-500/90 to-purple-500/90",
  },
];

// Different hover variants for animation
const hoverVariants = {
  scale: {
    scale: 1.05,
    transition: { duration: 0.3 },
  },
  rotate: {
    rotateX: 10,
    rotateY: 10,
    transition: { duration: 0.3 },
  },
  lift: {
    y: -10,
    transition: { duration: 0.3 },
  },
};

const WinWinPartnershipSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.div className="space-y-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          Win-win Partnership
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 + index * 0.1 }}
              whileHover={hoverVariants.scale} // You can change this to hoverVariants.rotate or hoverVariants.lift
              className="bg-gradient-to-br from-gray-900/90 to-black/90 p-8 rounded-2xl border border-white/10 backdrop-blur-sm"
            >
              <div className="flex flex-col items-start gap-6">
                <div
                  className={`w-16 h-16 bg-gradient-to-br ${benefit.gradient} rounded-2xl flex items-center justify-center transform transition-transform duration-300`}
                >
                  <benefit.icon className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-white mb-3 transition-transform duration-300">
                    {benefit.title}
                  </h3>
                  <p className="text-white/80 text-lg leading-relaxed transition-transform duration-300">
                    {benefit.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </MatrixSlideLayout>
  );
};

export default WinWinPartnershipSlide;
