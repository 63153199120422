import { FC } from "react";
import { motion } from "framer-motion";
import ParticlesBackground from "../Slides/components/ParticlesBackground";

const timelinePoints = [
  {
    title: "What is a Prompt?",
    content:
      "A prompt is the initial input or question given to a GenAI system, serving as the starting point for AI-generated content or analysis.",
  },
  {
    title: "Prompt Structures",
    content:
      "Prompts can vary from a single line to complex multi-prompt instructions, building on each other for more detailed and nuanced outputs.",
  },
  {
    title: "Use in Investment Research",
    content:
      "In investment research, prompts range from simple information retrieval queries to complex instructions of multi-step manual workflows, guiding the AI through financial analysis tasks.",
  },
];

const PromptDefinitionSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <ParticlesBackground />
      </div>
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-white mb-16 text-center"
        >
          Definition of a Prompt in the Context of GenAI
        </motion.h2>

        <div className="relative">
          <motion.div
            className="absolute left-1/3 -translate-x-1/2 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500/50 via-purple-500/50 to-blue-500/50"
            initial={{ height: 0 }}
            animate={{ height: "100%" }}
            transition={{ duration: 1, delay: 0.5 }}
          />

          <div className="space-y-24 relative flex flex-col items-center">
            {timelinePoints.map((point, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.8 + index * 0.2 }}
                className="flex items-center gap-4"
              >
                {/* Left side - Heading */}
                <div className="w-1/3 pr-8 text-right">
                  <motion.h3
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 1 + index * 0.2 }}
                    className="text-2xl font-semibold text-white text-right"
                  >
                    {point.title}
                  </motion.h3>
                </div>

                {/* Center - Timeline Node */}
                <div className="relative flex-shrink-0 z-10">
                  <div className="w-6 h-6 rounded-full bg-blue-500 relative z-10" />
                </div>

                {/* Right side - Content */}
                <div className="flex-1 pl-8 relative z-10">
                  <motion.p
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 1.2 + index * 0.2 }}
                    className="text-white/80 text-lg leading-relaxed"
                  >
                    {point.content}
                  </motion.p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptDefinitionSlide;
