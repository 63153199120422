import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const IntroSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="text-center">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          src="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
          alt="Quantly Logo"
          className="desktop:w-96 laptop:w-96 tablet:w-80 mobile:w-64 h-auto mx-auto mb-12"
        />
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-2xl text-gray-900 mb-6"
        >
          Transforming Research with Jefferies
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-2xl text-gray-600"
        ></motion.p>
      </div>
    </MatrixSlideLayout>
  );
};

export default IntroSlide;
