import { FC, useState } from "react";
import { motion } from "framer-motion";
import { HiExclamationCircle } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

// Design variations for the cards
const designVariants = {
  // Design 1: Deep blue gradient with purple accents
  dark1: {
    background: "from-blue-950 via-gray-900 to-blue-950",
    border: "border-blue-800/20",
    iconColor: "text-purple-400",
    titleColor: "text-white",
    textColor: "text-white/80",
    hoverGlow: "from-purple-500/10 via-blue-500/10 to-purple-500/10",
  },
  // Design 2: Dark purple gradient with red accents
  dark2: {
    background: "from-purple-950 via-gray-900 to-purple-950",
    border: "border-purple-800/20",
    iconColor: "text-red-400",
    titleColor: "text-white",
    textColor: "text-white/90",
    hoverGlow: "from-red-500/10 via-purple-500/10 to-red-500/10",
  },
  // Design 3: Deep gray gradient with emerald accents
  dark3: {
    background: "from-gray-950 via-black to-gray-950",
    border: "border-gray-800/20",
    iconColor: "text-emerald-400",
    titleColor: "text-white",
    textColor: "text-gray-300",
    hoverGlow: "from-emerald-500/10 via-gray-500/10 to-emerald-500/10",
  },
};

const pitfalls = [
  {
    title: "Overly broad or vague prompts",
    description:
      'Generic queries like "Analyze Company X" yield unfocused results, missing critical aspects of financial analysis.',
  },
  {
    title: "Assuming too much AI knowledge",
    description:
      "While AI has broad knowledge, it may lack nuance from knowledge it doesn't have access to. Don't assume it knows everything!",
  },
  {
    title: "Ignoring the AI's limitations",
    description:
      "AI may struggle with real-time data or analysis that requires complex reasoning and assumptions. It's not magic!",
  },
  {
    title: "Failing to provide necessary context",
    description:
      "Without proper background on a company's history or industry trends, AI may miss crucial factors in its analysis.",
  },
  {
    title: "Overreliance on AI-generated information",
    description:
      "Treating AI outputs as definitive without human verification can lead to overlooking critical nuances or potential errors in financial analysis.",
  },
  {
    title: "Expecting it to make decisions for you",
    description:
      "LLMs are great at retrieving, synthesising and summarising information. They should not and will not make investment decisions on your behalf. This is still your job as an analyst!",
  },
];

const CommonPitfallsSlide: FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // Choose your preferred design variant here
  const design = designVariants.dark1; // Try dark1, dark2, or dark3

  return (
    <div className="min-h-screen bg-black py-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-white mb-16 text-center"
        >
          Common Pitfalls and How To Avoid Them
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {pitfalls.map((pitfall, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${design.hoverGlow} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
              />
              <motion.div
                className={`relative bg-gradient-to-br ${design.background} p-8 rounded-2xl border ${design.border} backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 h-full`}
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.3)" }}
              >
                <div className="flex items-start gap-4 mb-4">
                  <HiExclamationCircle
                    className={`w-6 h-6 ${design.iconColor} flex-shrink-0 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300`}
                  />
                  <h3
                    className={`text-xl font-semibold ${design.titleColor} group-hover:translate-y-[-2px] transition-transform duration-300`}
                  >
                    {pitfall.title}
                  </h3>
                </div>
                <p
                  className={`${design.textColor} text-lg leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300`}
                >
                  {pitfall.description}
                </p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommonPitfallsSlide;
