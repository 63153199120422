import { FC } from "react";
import { motion } from "framer-motion";
import { HiShieldCheck, HiLockClosed, HiServer, HiUsers } from "react-icons/hi";

const segments = [
  {
    icon: HiLockClosed,
    title: "Data Privacy",
    description:
      "Complete data privacy with zero data sharing outside your environment",
  },
  {
    icon: HiShieldCheck,
    title: "Compliance",
    description:
      "Full adherence to regulatory requirements and internal compliance standards",
  },
  {
    icon: HiServer,
    title: "Infrastructure Control",
    description:
      "Deployed directly on your infrastructure for complete control",
  },
  {
    icon: HiUsers,
    title: "Customer Success",
    description: "Successfully deployed for multiple enterprise customers",
  },
];

const SecurityShield: FC = () => {
  return (
    <div className="relative w-full max-w-4xl mx-auto">
      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {segments.map((segment, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
            <motion.div
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 h-full"
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <segment.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                {segment.title}
              </h3>
              <p className="text-white/80 text-lg group-hover:translate-y-[-2px] transition-transform duration-300">
                {segment.description}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          </motion.div>
        ))}
      </div>
      <motion.div
        className="absolute inset-0 pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1 }}
      >
        <svg
          className="w-full h-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M20,50 L80,20 L80,80 L20,50"
            stroke="currentColor"
            strokeWidth="0.5"
            fill="none"
          />
          <path
            d="M20,50 L80,80 M20,50 L80,20"
            stroke="currentColor"
            strokeWidth="0.5"
            fill="none"
            strokeDasharray="4 4"
          />
        </svg>
      </motion.div>
    </div>
  );
};

export default SecurityShield;
