import { FC } from "react";
import { motion } from "framer-motion";

interface ProposalPricingCardProps {
  title: string;
  price: number;
  description: string;
  isStandard: boolean;
}

const ProposalPricingCard: FC<ProposalPricingCardProps> = ({
  title,
  price,
  description,
  isStandard,
}) => {
  const formattedPrice = price.toLocaleString();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="relative group"
    >
      <div
        className={`absolute inset-0 bg-gradient-to-br ${
          isStandard
            ? "from-gray-500/20 via-gray-400/20 to-gray-500/20"
            : "from-blue-500/20 via-purple-500/20 to-blue-500/20"
        } blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl`}
      />

      <motion.div
        className="relative bg-gradient-to-br from-gray-900/90 to-black/90 p-8 rounded-2xl border border-white/10 backdrop-blur-sm overflow-hidden"
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
        <div className="flex items-baseline gap-2 mb-2">
          <span className="text-4xl font-bold text-white">
            ${formattedPrice}
          </span>
          <span className="text-white/60">{description}</span>
        </div>

        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
          initial={{ x: "-200%" }}
          animate={{ x: "200%" }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
            ease: "linear",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default ProposalPricingCard;
