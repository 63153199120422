import { FC } from "react";
import { motion } from "framer-motion";
import { HiCheck } from "react-icons/hi";

const benefits = [
  "All European & UK analysts included",
  "Single annual fee vs. standard $3k per seat",
  "Flexible analyst additions within region",
  "Full feature access",
  "Priority support and customization",
];

const EnterpriseBenefits: FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6 }}
      className="bg-gradient-to-br from-gray-900/90 to-black/90 p-8 rounded-2xl border border-white/10 backdrop-blur-sm h-full"
    >
      <h3 className="text-2xl font-bold text-white mb-6">
        Enterprise Benefits
      </h3>
      <div className="space-y-4">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="flex items-center gap-3"
          >
            <div className="w-6 h-6 bg-green-500/20 rounded-full flex items-center justify-center flex-shrink-0">
              <HiCheck className="w-4 h-4 text-green-400" />
            </div>
            <span className="text-white/80">{benefit}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default EnterpriseBenefits;
