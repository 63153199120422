import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt } from "react-icons/hi";
import MatrixSlideLayout from "./layouts/MatrixSlideLayout";

const SolutionSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="flex flex-col items-center justify-center min-h-[80vh]">
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
          {/* Left side - Title with animated underline */}
          <div className="space-y-6">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-gray-900 leading-tight"
            >
              {" "}
              <motion.img
                src="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
                alt="Quantly Logo"
                className="w-32 mb-8 cursor-pointer"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <span className="relative inline-block mt-2">
                A personal AI assistant,
              </span>
              <br />
              <span className="relative inline-block mt-2">
                for every analyst{" "}
                <motion.div
                  className="absolute bottom-0 left-0 h-1 bg-black"
                  initial={{ width: 0 }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 1, delay: 0.5 }}
                />
              </span>
            </motion.h2>
          </div>

          {/* Right side - Platform description card */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />

            <motion.div
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-10 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500"
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <HiLightningBolt className="w-16 h-16 mb-8 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />

              <h3 className="text-3xl font-semibold mb-6 text-white leading-tight">
                Quantly is an LLM-based investment research platform
              </h3>

              <p className="text-xl text-white/90 leading-relaxed">
                that allows analysts to get research insights and
                automate manual research work, across internal and external sources of data.
              </p>

              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default SolutionSlide;
