import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiChip, HiCog, HiSparkles } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const concepts = [
  {
    icon: HiSparkles,
    title: "What is Generative AI?",
    description:
      "AI that creates new content like text, images, and music. Think of it like a creative assistant that can write stories or compose melodies.",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
  {
    icon: HiChip,
    title: "Large Language Models (LLMs)",
    description:
      "Advanced AI systems trained on vast amounts of text data to understand and generate human-like language. They're like highly sophisticated text prediction engines.",
    gradient: "from-purple-500/20 via-indigo-500/20 to-purple-500/20",
  },
  {
    icon: HiCog,
    title: "How They Work",
    description:
      "Using deep learning techniques, these models analyze patterns in data to predict and generate content, similar to how humans learn from examples and create new ideas.",
    gradient: "from-indigo-500/20 via-blue-500/20 to-indigo-500/20",
  },
  {
    icon: HiLightningBolt,
    title: "Real-World Applications",
    description:
      "From chatbots and content creation to advanced data analysis in investment research, these technologies are transforming how we work with information.",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
];

const FeaturesSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="space-y-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Introduction to GenAI and LLMs
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Understanding the technologies reshaping investment research
          </p>
        </motion.div>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8 max-w-6xl mx-auto">
          {concepts.map((concept, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${concept.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
              />
              <motion.div
                className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 h-full"
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
              >
                <concept.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                  {concept.title}
                </h3>
                <p className="text-white/80 text-lg leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                  {concept.description}
                </p>
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default FeaturesSlide;
