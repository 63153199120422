import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDatabase,
  HiDocumentText,
  HiLockClosed,
  HiArrowsExpand,
} from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const LimitationsPotentialSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Future-Proof Research Business
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {/* Current State - Left Side */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="relative"
        >
          <div className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full">
            <h3 className="text-2xl font-semibold mb-6">Current State</h3>

            {/* External Data Source */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-white/10 p-6 rounded-xl mb-4"
            >
              <HiDatabase className="w-8 h-8 text-blue-400 mb-3" />
              <h4 className="text-xl font-medium mb-2">External Data Only</h4>
              <p className="text-white/80">
                Limited to analyzing external market data and public information
              </p>
            </motion.div>

            {/* Limitation Indicator */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="bg-red-900/30 p-6 rounded-xl"
            >
              <HiLockClosed className="w-8 h-8 text-red-400 mb-3" />
              <h4 className="text-xl font-medium mb-2">Limited Capabilities</h4>
              <p className="text-white/80">
                Only partial feature access due to compliance and security
                concerns
              </p>
            </motion.div>

            {/* Animated Flow Lines */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
              initial={{ x: "-200%" }}
              animate={{ x: "200%" }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
              }}
            />
          </div>
        </motion.div>

        {/* Future Potential - Right Side */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="relative"
        >
          <div className="bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 p-8 rounded-2xl text-white h-full">
            <h3 className="text-2xl font-semibold mb-6">Future Potential</h3>

            {/* Combined Data Sources */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="bg-white/10 p-6 rounded-xl mb-4"
            >
              <HiArrowsExpand className="w-8 h-8 text-blue-400 mb-3" />
              <h4 className="text-xl font-medium mb-2">Unified Data Access</h4>
              <p className="text-white/80">
                Seamlessly combine internal research with external market data
              </p>
            </motion.div>

            {/* Enhanced Capabilities */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
              className="bg-green-900/30 p-6 rounded-xl"
            >
              <HiDocumentText className="w-8 h-8 text-green-400 mb-3" />
              <h4 className="text-xl font-medium mb-2">
                True Competitive Advantage
              </h4>
              <p className="text-white/80">
                LLMs as a personal assistant for every analyst, leveraging both
                internal and external knowledge
              </p>
            </motion.div>

            {/* Animated Flow Lines */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0"
              initial={{ x: "-200%" }}
              animate={{ x: "200%" }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
              }}
            />
          </div>
        </motion.div>

        {/* Connecting Flow Line */}
        <motion.div
          className="absolute top-1/2 left-1/2 w-8 h-0.5 bg-blue-500/50 -translate-x-1/2 -translate-y-1/2 desktop:block laptop:block tablet:hidden mobile:hidden"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        />
      </div>
    </MatrixSlideLayout>
  );
};

export default LimitationsPotentialSlide;
