import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDatabase,
  HiDocumentText,
  HiGlobeAlt,
  HiChartBar,
} from "react-icons/hi";

const externalData = [
  {
    icon: HiDocumentText,
    title: "Global Company Coverage",
    items: [
      "Annual & quarterly reports",
      "Earnings calls transcripts",
      "Press releases & company presentations",
    ],
  },
  {
    icon: HiChartBar,
    title: "Consensus Estimates",
  },
  {
    icon: HiGlobeAlt,
    title: "Global News",
  },
];

const DataAvailabilitySlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Data Availability
        </motion.h2>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="space-y-6"
          >
            <div className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl hover:shadow-2xl hover:bg-gray-50 transition-all duration-300">
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">
                Market-Leading Data Partnerships
              </h3>
              <p className="text-xl text-gray-600 mb-4">
                Powered by S&P Capital IQ with Bloomberg integration capabilities
              </p>
              <div className="flex items-center gap-4">
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  S&P Capital IQ
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  Bloomberg
                </div>
              </div>
            </div>

            <div className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl hover:shadow-2xl hover:bg-gray-50 transition-all duration-300">
              <HiDatabase className="w-8 h-8 mb-4 text-gray-900" />
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">
                Your Internal Research Data
              </h3>
              <div className="flex flex-wrap gap-3">
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  PDFs & Documents
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  Excel Files
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  Databases
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  API Data Streams
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  Email
                </div>
                <div className="bg-gray-100 px-4 py-2 rounded-lg text-gray-900">
                  Any other data source
                </div>
              </div>
            </div>
          </motion.div>

          <div className="space-y-4">
            {externalData.map((data, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white shadow-xl border border-gray-100 p-6 rounded-2xl hover:shadow-2xl hover:bg-gray-50 transition-all duration-300"
              >
                <div className="flex items-start gap-4">
                  <data.icon className="w-8 h-8 flex-shrink-0 text-gray-900" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">{data.title}</h3>
                    {data.items && (
                      <ul className="space-y-1 text-gray-600">
                        {data.items.map((item, idx) => (
                          <li key={idx}>• {item}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAvailabilitySlide;
