import { motion } from "framer-motion";
import { FC } from "react";
import MainHeadline from "./MainHeadline";
import CTAButtons from "./CTAButtons";

const MainContent: FC = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.4 }}
    className="desktop:col-span-7 laptop:col-span-7 tablet:col-span-1 mobile:col-span-1"
  >
    <MainHeadline />
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="text-gray-600 desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base leading-relaxed mb-14 desktop:pr-16 laptop:pr-16 tablet:pr-0 mobile:pr-0 font-light"
    >
      Transform your investment research organisation with Quantly's
      customisable, enterprise-grade research platform. Designed for
      institutions with the highest security and quality requirements.
    </motion.p>
    <CTAButtons />
  </motion.div>
);

export default MainContent;
