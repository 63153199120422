import { FC } from "react";
import Logo from "./components/Logo";
import ContentContainer from "./components/ContentContainer";
import Navigation from "../Navigation/Navigation";
import AlertBanner from "./components/AlertBanner";

const HeroSection: FC = () => {
  return (
    <section className="relative min-h-screen desktop:pb-32 laptop:pb-32 tablet:pb-24 mobile:pb-24 w-full overflow-hidden isolate">
      {" "}
      <AlertBanner />
      <Logo />
      <Navigation />
      <ContentContainer />
    </section>
  );
};

export default HeroSection;
