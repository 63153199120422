import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDatabase,
  HiDocumentText,
  HiTable,
  HiCloud,
  HiGlobeAlt,
  HiLightningBolt,
  HiCode,
  HiCog,
  HiCheckCircle,
  HiTemplate,
  HiChip,
  HiSparkles,
  HiUserGroup,
  HiCurrencyDollar,
  HiKey,
  HiCash,
  HiChartSquareBar,
  HiClipboardList,
  HiChartBar,
} from "react-icons/hi";

const QuantlyFutureSlide: FC = () => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-start p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto w-full">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-5xl font-bold text-gray-900 mb-4 text-center"
        >
          Our Direction Of Travel: Investment Research LLM Infrastructure Layer
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-xl text-gray-600 mb-8 text-center"
        >
          It won't be a choice between Quantly and Enterprise ChatGPT
        </motion.p>

        {/* Architecture Diagram */}
        <div className="relative h-[500px] mx-auto w-full max-w-[2000px] mb-12">
          {/* Layer Labels */}
          <div className="absolute top-0 w-full flex justify-between text-sm font-semibold text-gray-600 px-8 py-2 border-b border-gray-200 bg-white/50">
            <span className="w-1/4 text-center">Data Sources</span>
            <span className="w-1/4 text-center">Processing Engine</span>
            <span className="w-1/4 text-center">Output Interfaces</span>
            <span className="w-1/4 text-center">User Groups</span>
          </div>

          {/* Main Content Columns */}
          <div className="relative flex h-full pt-12">
            {/* Left Column - Data Sources with Knowledge Graph */}
            <div className="flex-1 flex flex-col items-center border-r border-gray-200 px-6 space-y-6">
              {/* Knowledge Graph Container */}
              <motion.div
                className="w-full bg-gradient-to-r from-gray-900 to-blue-900 text-white shadow-lg border border-gray-800 p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
              >
                <h4 className="font-medium text-center mb-6 text-white">Knowledge Graph</h4>
                <div className="space-y-6">
                  {/* Internal Data */}
                  <div>
                    <h5 className="text-white font-medium mb-3">Internal Data</h5>
                    <div className="space-y-3">
                      {[
                        { icon: HiDocumentText, text: "PDFs & Documents" },
                        { icon: HiTable, text: "Excel Files" },
                        { icon: HiDatabase, text: "Databases" },
                        { icon: HiCloud, text: "API Data Streams" },
                      ].map((item, i) => (
                        <div key={i} className="flex items-center gap-3 text-white">
                          <item.icon className="w-5 h-5 text-white" />
                          <span>{item.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* External Data */}
                  <div>
                    <h5 className="text-white font-medium mb-3">External Data</h5>
                    <div className="flex items-center gap-3 text-white">
                      <HiGlobeAlt className="w-5 h-5 text-white" />
                      <span>3rd Party Data Providers</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Center Column - Processing */}
            <div className="flex-1 flex flex-col items-center px-6 space-y-6">
              {/* Top Features */}
              <div className="grid grid-cols-2 gap-3 w-full">
                {[
                  { icon: HiLightningBolt, text: "Specialised LLM Agents" },
                  { icon: HiCode, text: "Data Analysis" },
                  { icon: HiCog, text: "Automatic Prompt Improvement" },
                  { icon: HiCheckCircle, text: "Source Attribution" },
                ].map((feature, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 + i * 0.1 }}
                    className="bg-white shadow-lg border border-gray-100 p-4 rounded-xl"
                  >
                    <div className="flex items-center gap-3">
                      <feature.icon className="w-5 h-5 text-gray-900" />
                      <span className="text-sm font-medium text-gray-900">{feature.text}</span>
                    </div>
                  </motion.div>
                ))}
              </div>

              {/* Engine */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="w-[300px] bg-black p-6 rounded-xl shadow-xl"
              >
                <h3 className="text-lg font-bold text-white text-center">
                  Quantly Reasoning Engine
                </h3>
              </motion.div>

              {/* Bottom Features - NEW */}
              <div className="w-full flex flex-col gap-3">
                {[
                  { icon: HiChip, text: "Smart Model Routing" },
                  { icon: HiSparkles, text: "Small Model Finetuning" },
                  { icon: HiUserGroup, text: "Automatic Personalisation" },
                ].map((feature, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.9 + i * 0.1 }}
                    className="w-full bg-gradient-to-r from-gray-900 to-blue-900 text-white shadow-lg border border-gray-800 p-4 rounded-xl"
                  >
                    <div className="flex items-center gap-3">
                      <feature.icon className="w-5 h-5 text-white shrink-0" />
                      <span className="text-sm font-medium">{feature.text}</span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Right Column - Output */}
            <div className="flex-1 flex flex-col items-center border-l border-gray-200 px-6 space-y-6">
              {[
                { icon: HiTemplate, title: "Autonomous Workflows", isNew: true },
                { icon: HiLightningBolt, title: "AI Analyst Assistant", isNew: false },
                { icon: HiCheckCircle, title: "Proactive Insights", isNew: true },
                { icon: HiCurrencyDollar, title: "Data Marketplace", isNew: true },
                { icon: HiDatabase, title: "Bulk Processing", isNew: true },
              ].map((output, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 + i * 0.1 }}
                  className={`w-full shadow-lg border ${output.isNew ? 'border-gray-800' : 'border-gray-100'} p-6 rounded-xl ${
                    output.isNew 
                      ? "bg-gradient-to-r from-gray-900 to-blue-900 text-white" 
                      : "bg-white"
                  }`}
                >
                  <div className="flex items-center gap-3">
                    <output.icon className={`w-6 h-6 ${output.isNew ? "text-white" : "text-gray-900"}`} />
                    <h4 className={output.isNew ? "font-medium text-white" : "text-gray-900 font-medium"}>
                      {output.title}
                    </h4>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Right Column - User Groups */}
            <div className="flex-1 flex flex-col items-center px-6 space-y-6">
              {[
                { icon: HiUserGroup, title: "Research", isNew: false },
                { icon: HiUserGroup, title: "Trading", isNew: true },
                { icon: HiUserGroup, title: "Sales", isNew: true },
                { icon: HiUserGroup, title: "Marketing/IR", isNew: true },
                { icon: HiUserGroup, title: "M&A", isNew: true },
              ].map((group, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 + i * 0.1 }}
                  className={`w-full shadow-lg border ${group.isNew ? 'border-gray-800' : 'border-gray-100'} p-6 rounded-xl ${
                    group.isNew 
                      ? "bg-gradient-to-r from-gray-900 to-blue-900 text-white" 
                      : "bg-white"
                  }`}
                >
                  <div className="flex items-center gap-3">
                    <group.icon className={`w-6 h-6 ${group.isNew ? "text-white" : "text-gray-900"}`} />
                    <h4 className={group.isNew ? "font-medium text-white" : "text-gray-900 font-medium"}>
                      {group.title}
                    </h4>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Management & Governance Layer */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
          className="w-full max-w-[2000px] mt-4"
        >
          {/* Layer Label */}
          <div className="w-full text-sm font-semibold text-gray-600 px-8 py-2 border-b border-gray-200 bg-white/50 text-center mb-4">
            Management & Governance Layer
          </div>

          {/* Containers */}
          <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-4 px-8">
            {[
              { icon: HiKey, title: "Role Based Access Control", isNew: true },
              { icon: HiCash, title: "Cost Management", isNew: true },
              { icon: HiChartSquareBar, title: "Evals and Monitoring", isNew: true },
              { icon: HiClipboardList, title: "Audit Logs", isNew: true },
            ].map((item, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3 + i * 0.1 }}
                className={`shadow-lg border ${item.isNew ? 'border-gray-800' : 'border-gray-100'} p-4 rounded-xl ${
                  item.isNew 
                    ? "bg-gradient-to-r from-gray-900 to-blue-900 text-white" 
                    : "bg-white"
                }`}
              >
                <div className="flex items-center gap-2">
                  <item.icon className={`w-5 h-5 ${item.isNew ? "text-white" : "text-gray-900"}`} />
                  <h4 className={item.isNew ? "font-medium text-white text-sm" : "text-gray-900 font-medium text-sm"}>
                    {item.title}
                  </h4>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default QuantlyFutureSlide; 