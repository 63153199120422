import { FC } from "react";
import {
  HiBeaker,
  HiScale,
  HiChartBar,
  HiUserGroup,
  HiTemplate,
  HiLightningBolt,
} from "react-icons/hi";
import ParticlesBackground from "../Slides/components/ParticlesBackground";

const steps = [
  {
    icon: HiBeaker,
    title: "Start With Low-Stakes Tasks",
    description: "Test Quantly for data gathering and summarization",
    gradient: "from-blue-900 via-blue-800 to-blue-900",
  },
  {
    icon: HiScale,
    title: "Run Parallel Analysis",
    description: "Compare efficiency and accuracy with traditional methods",
    gradient: "from-purple-900 via-purple-800 to-purple-900",
  },
  {
    icon: HiChartBar,
    title: "Expand Gradually",
    description: "Progress from simple to complex analyses as confidence grows",
    gradient: "from-indigo-900 via-indigo-800 to-indigo-900",
  },
  {
    icon: HiUserGroup,
    title: "Foster Collaboration",
    description: "Use AI insights as a basis for team discussions",
    gradient: "from-blue-900 via-blue-800 to-blue-900",
  },
  {
    icon: HiTemplate,
    title: "Customize Outputs",
    description: "Align AI outputs with firm's report formats and requirements",
    gradient: "from-purple-900 via-purple-800 to-purple-900",
  },
  {
    icon: HiLightningBolt,
    title: "Enhance Efficiency",
    description: "Leverage AI to uncover new insights and stay competitive",
    gradient: "from-indigo-900 via-indigo-800 to-indigo-900",
  },
];

const IntegrationProcessSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <ParticlesBackground />
      </div>
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Integrating AI Into Your Research Process
        </h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <div
                className={`relative bg-gradient-to-br ${step.gradient} p-6 rounded-2xl border border-white/10 backdrop-blur-sm min-h-[280px] flex flex-col`}
              >
                <step.icon className="w-10 h-10 mb-4 text-white" />
                <h3 className="text-xl font-semibold mb-3 text-white">
                  {step.title}
                </h3>
                <p className="text-white/80 text-base">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntegrationProcessSlide;
