import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Slides from "./pages/Slides";
import FIL from "./pages/FIL";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Data from "./pages/Data";
import Product from "./pages/Product";
import BusinessCard from "./pages/BusinessCard";
import Jefferies from "./pages/Jefferies";
import Guide from "./pages/Guide";
import API from "./pages/API";

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/slides" element={<Slides />} />
        <Route path="/fil" element={<FIL />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/data" element={<Data />} />
        <Route path="/product" element={<Product />} />
        <Route path="/businesscard" element={<BusinessCard />} />
        <Route path="/jefferies" element={<Jefferies />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/api" element={<API />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
