import { FC, useState } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const LeadingQuestionsSlide: FC = () => {
  const [activeElements, setActiveElements] = useState<string[]>([]);

  const toggleElement = (element: string) => {
    if (activeElements.includes(element)) {
      setActiveElements(activeElements.filter((e) => e !== element));
    } else {
      setActiveElements([...activeElements, element]);
    }
  };

  const questionParts = [
    {
      id: "quantity",
      text: "three most significant",
      description: "Specific quantity constraint",
    },
    {
      id: "focus",
      text: "factors impacting",
      description: "Analysis focus",
    },
    {
      id: "metric",
      text: "profit margins",
      description: "Target metric",
    },
    {
      id: "timeframe",
      text: "last four quarterly reports",
      description: "Time period constraint",
    },
  ];

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Leading Questions Technique
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center">
        {/* Left Side - Concept Explanation */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <p className="text-xl mb-6">
            Leading and targeted questions guide the AI towards specific areas
            of analysis, types of insights and sources of information.
          </p>
          <ul className="space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-xl text-white/80"
            >
              They can help focus the AI's response on particular aspects of
              financial data, source or company performance.
            </motion.li>
          </ul>
        </motion.div>

        {/* Right Side - Interactive Example */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white h-full"
        >
          <h3 className="text-xl font-semibold mb-6">Interactive Example</h3>
          <p className="text-xl text-white/80 mb-6">
            Click elements to analyze the structure:
          </p>

          <div className="space-y-4">
            <p className="text-lg font-mono p-4 bg-black/30 rounded-xl">
              "What are the{" "}
              <span
                onClick={() => toggleElement("quantity")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("quantity")
                    ? "text-blue-400"
                    : "text-white/80 hover:text-blue-400/80"
                }`}
              >
                three most significant
              </span>{" "}
              <span
                onClick={() => toggleElement("focus")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("focus")
                    ? "text-purple-400"
                    : "text-white/80 hover:text-purple-400/80"
                }`}
              >
                factors impacting
              </span>{" "}
              Company Y's{" "}
              <span
                onClick={() => toggleElement("metric")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("metric")
                    ? "text-green-400"
                    : "text-white/80 hover:text-green-400/80"
                }`}
              >
                profit margins
              </span>
              , based on their{" "}
              <span
                onClick={() => toggleElement("timeframe")}
                className={`cursor-pointer transition-colors duration-300 ${
                  activeElements.includes("timeframe")
                    ? "text-yellow-400"
                    : "text-white/80 hover:text-yellow-400/80"
                }`}
              >
                last four quarterly reports
              </span>
              ?"
            </p>

            <div className="space-y-2">
              {questionParts.map(
                (part) =>
                  activeElements.includes(part.id) && (
                    <motion.div
                      key={part.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="bg-white/10 p-4 rounded-xl"
                    >
                      <span className="text-sm font-medium">
                        {part.description}:
                      </span>{" "}
                      <span className="text-white/80">{part.text}</span>
                    </motion.div>
                  ),
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default LeadingQuestionsSlide;
