import { FC } from "react";
import { motion } from "framer-motion";
import { HiClock, HiDatabase, HiLightningBolt } from "react-icons/hi";
import MatrixBackground from "../Slides/components/ParticlesBackground";

const points = [
  {
    icon: HiClock,
    title: "Enhanced Efficiency",
    content:
      "Analysts can automate repetitive tasks, enabling stronger focus on strategic decision-making",
  },
  {
    icon: HiDatabase,
    title: "Data-Driven Insights",
    content:
      "Extract structured insights from vast unstructured datasets for deeper market understanding",
  },
  {
    icon: HiLightningBolt,
    title: "Single Source of Truth",
    content:
      "A centralized platform that consolidates multiple data sources and information streams to create a unified research hub for analysts",
  },
];

const ImpactSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <MatrixBackground />
      </div>
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="desktop:text-5xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-white mb-12 text-center"
        >
          Impact on Investment Practices
        </motion.h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {points.map((point, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl" />
              <div className="min-h-[320px] bg-gradient-to-br from-gray-900 via-black to-gray-800 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-105 transition-all duration-500 relative overflow-hidden">
                <point.icon className="w-12 h-12 text-white/80 mb-6 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h3 className="text-2xl font-bold text-white mb-5 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {point.title}
                </h3>
                <p className="text-white/95 text-xl leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                  {point.content}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImpactSlide;
