import { FC } from "react";
import {
  HiAcademicCap,
  HiClipboardList,
  HiLightBulb,
  HiUserGroup,
} from "react-icons/hi";

const roleAdaptations = [
  {
    icon: HiAcademicCap,
    title: "Skill Evolution",
    points: [
      "Develop AI prompt engineering expertise",
      "Enhance data interpretation and validation skills",
      "Focus on strategic thinking, scenario analysis and asking the right questions",
    ],
  },
  {
    icon: HiClipboardList,
    title: "Changing Responsibilities",
    points: [
      "Shift from data gathering to insight synthesis",
      "Increase focus on qualitative factors and unique company dynamics",
      "Greater emphasis on risk assessment and model stress-testing",
    ],
  },
  {
    icon: HiLightBulb,
    title: "New Areas of Expertise",
    points: [
      "AI model understanding and limitations",
      "Integration of alternative data sources",
      "Ethical considerations in AI-assisted research",
    ],
  },
  {
    icon: HiUserGroup,
    title: "Career Impact",
    points: [
      "Transition to higher-value analytical and research tasks",
      "Increased collaboration with data scientists and AI specialists",
      "Potential for broader coverage and deeper analysis",
    ],
  },
];

const DesignThree: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-800">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Adapting the Investment Research Role for an AI-Augmented Future
        </h2>

        <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {roleAdaptations.map((role, index) => (
            <div
              key={index}
              className="bg-gradient-to-br from-gray-800 to-black p-6 rounded-2xl text-white h-full"
            >
              <div className="flex flex-col items-start gap-6">
                <div className="w-12 h-12 bg-black/50 rounded-xl flex items-center justify-center">
                  <role.icon className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-3xl font-semibold mb-4 text-white">
                    {role.title}
                  </h3>
                  <ul className="space-y-2">
                    {role.points.map((point, pointIndex) => (
                      <li
                        key={pointIndex}
                        className="text-white/80 flex items-start gap-2 text-lg"
                      >
                        <span className="text-white/60 mt-1">•</span>
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignThree;
