import { FC } from "react";
import { motion } from "framer-motion";

const patronusData = [
  { name: "Quantly", accuracy: 94, color: "bg-blue-500" },
  { name: "GPTo1-preview", accuracy: 62.4, color: "bg-gray-400" },
  { name: "Claude3.5 Sonnet", accuracy: 56.7, color: "bg-gray-400" },
  { name: "GPT4o-mini", accuracy: 52.0, color: "bg-gray-400" },
];

const AccuracyScore: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex items-end justify-center space-x-8 h-[300px]">
        {patronusData.map((item, index) => (
          <div key={index} className="flex flex-col items-center group">
            <motion.div 
              className="relative w-16"
              initial={{ height: 0, opacity: 0, scale: 0.8 }}
              whileInView={{ 
                height: `${item.accuracy * 2.5}px`, 
                opacity: 1, 
                scale: 1,
              }}
              viewport={{ once: true }}
              transition={{
                height: {
                  duration: 1.2,
                  delay: index * 0.2,
                  ease: "easeOut"
                },
                opacity: {
                  duration: 0.3,
                  delay: index * 0.2
                },
                scale: {
                  duration: 0.4,
                  delay: index * 0.2,
                  ease: "easeOut"
                }
              }}
            >
              <motion.div 
                className={`absolute bottom-0 w-full ${item.color} rounded-t-lg shadow-lg transition-all duration-300 cursor-pointer`}
                style={{ height: `${item.accuracy * 2.5}px` }}
                initial={{ boxShadow: "0 0 0 rgba(0,0,0,0)" }}
                whileInView={{ 
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                }}
                whileHover={{ 
                  scale: 1.1,
                  boxShadow: "0 8px 24px rgba(0,0,0,0.15)",
                }}
                viewport={{ once: true }}
                transition={{ 
                  delay: (index * 0.2) + 0.8, 
                  duration: 0.2,
                  scale: {
                    type: "spring",
                    stiffness: 300,
                    damping: 15
                  }
                }}
              >
                <motion.div 
                  className="absolute -top-8 w-full text-center text-gray-900 font-bold transition-transform duration-300 group-hover:scale-110"
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ 
                    opacity: 1, 
                    y: 0,
                  }}
                  viewport={{ once: true }}
                  transition={{ delay: (index * 0.2) + 1, duration: 0.3 }}
                >
                  {item.accuracy}%
                </motion.div>
                
                {/* Hover glow effect */}
                <div className="absolute inset-0 rounded-t-lg opacity-0 group-hover:opacity-20 transition-opacity duration-300 bg-gradient-to-t from-white/0 via-white to-white/0" />
              </motion.div>
            </motion.div>
          </div>
        ))}
      </div>

      <div className="flex justify-center space-x-8 mt-8">
        {patronusData.map((item, index) => (
          <motion.div 
            key={index} 
            className="w-16 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ 
              opacity: 1, 
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{ delay: (index * 0.2) + 1.2, duration: 0.4 }}
          >
            <div className="text-gray-600 text-xs font-semibold whitespace-normal group-hover:text-gray-900 transition-colors duration-300">
              {item.name}
            </div>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 2, duration: 0.4 }}
        className="text-gray-500 text-xs mt-4 text-center space-y-1"
      >
        <p>Source: Evaluation of FinanceBench by Databricks, December 2024</p>
        <p>Based on 150 question-answer pairs</p>
      </motion.div>
    </div>
  );
};

export default AccuracyScore;
