import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiLightningBolt,
  HiChartBar,
  HiUserGroup,
  HiLightBulb,
} from "react-icons/hi";
import CircularProgress from "./components/CircularProgress";

const points = [
  {
    icon: HiLightBulb,
    title: "Focus on deep research and idea generation",
    description:
      "Shift from routine tasks to generating unique insights and deeper analysis",
  },
  {
    icon: HiLightningBolt,
    title: "Faster research, expanded coverage",
    description:
      "Near-instant analysis allows analysts to cover more ground",
  },
  {
    icon: HiChartBar,
    title: "Better insights",
    description:
      "Increased analyst tools and capabilities to drive better ideas and research",
  },
  {
    icon: HiUserGroup,
    title: "Higher client engagement",
    description:
      "More time for high-value client interactions and customized research delivery",
  },
];

const AIAugmentedAnalystSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          The Analyst with Quantly's LLM Infrastructure
        </motion.h2>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          {/* Left Panel - Points */}
          <div className="space-y-4">
            {points.map((point, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="flex items-start gap-6 bg-green-50 p-8 rounded-2xl border border-green-100 hover:shadow-lg transition-all duration-300"
              >
                <point.icon className="w-10 h-10 text-green-600 flex-shrink-0" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {point.title}
                  </h3>
                  <p className="text-gray-600 font-light">
                    {point.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Right Panel - 100% Metric */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="flex flex-col items-center justify-center bg-green-50 p-12 rounded-2xl border border-green-100"
          >
            <CircularProgress percentage={100} color="green" />
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.8 }}
              className="text-center mt-8 text-2xl text-gray-900 font-light leading-relaxed"
            >
              of time spent on high-value research, idea generation and client
              interactions
            </motion.p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AIAugmentedAnalystSlide;
