import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const points = [
  {
    content:
      "Well-crafted prompts lead to accurate and relevant responses by providing clear guidance to AI systems, directly impacting output quality.",
  },
  {
    content:
      "In investment research, precise prompts are crucial for obtaining deep, actionable insights rather than surface-level information.",
  },
];

const PromptQualitySlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-center min-h-[80vh]">
        <motion.h2
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-gray-900 leading-tight"
        >
          The relationship between prompt and output quality
        </motion.h2>

        <div className="space-y-8">
          {points.map((point, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white hover:scale-105 transition-all duration-300 group"
            >
              <p className="text-lg text-white/80 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                {point.content}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PromptQualitySlide;
