import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const articles = [
  {
    title: "Bank of America plans to spend $4bn on AI and new tech",
    source: "Bloomberg",
    link: "https://www.bloomberg.com/news/videos/2024-07-11/bofa-is-investing-a-lot-in-gen-ai-video"
  },
  {
    title: "JPMorgan Chase to equip 140K workers with generative AI tool",
    source: "CIO Dive",
    link: "https://www.ciodive.com/news/JPMorgan-Chase-LLM-Suite-generative-ai-employee-tool/726772/"
  },
  {
    title: "Morgan Stanley Announces Latest Game-Changing Addition to Suite of GenAI Tools",
    source: "NASDAQ",
    link: "https://www.nasdaq.com/press-release/morgan-stanley-wealth-management-announces-latest-game-changing-addition-suite-genai"
  },
  {
    title: "JPMorgan Unveils IndexGPT in Next Wall Street Bid to Tap AI Boom",
    source: "Bloomberg",
    link: "https://www.bloomberg.com/news/articles/2024-05-03/jpmorgan-unveils-indexgpt-in-next-wall-street-bid-to-tap-ai-boom"
  },
  {
    title: "How AI is Transforming Investing",
    source: "BlackRock",
    link: "https://www.blackrock.com/us/individual/insights/ai-investing"
  },
  {
    title: "AI: The Next Frontier in Investment Management",
    source: "Deloitte",
    link: "https://www.deloitte.com/global/en/Industries/financial-services/perspectives/ai-next-frontier-in-investment-management.html"
  }
];

const NewsArticlesSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="space-y-8">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 text-left"
        >
          Many firms are exploring how to best take advantage of GenAI
        </motion.h2>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {articles.map((article, index) => (
            <motion.a
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl hover:shadow-2xl hover:bg-gray-50 group transition-all duration-300"
            >
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-gray-900 group-hover:text-gray-700 transition-colors duration-300">
                  {article.title}
                </h3>
                <div className="flex items-center justify-between">
                  <p className="text-gray-600 font-medium">
                    Source: <span className="text-gray-900">{article.source}</span>
                  </p>
                  <span className="text-gray-400 group-hover:text-gray-600 transition-colors duration-300">
                    →
                  </span>
                </div>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default NewsArticlesSlide;
