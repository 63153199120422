import React from "react";
import Hero from "../components/Hero";
import AIPerformance from "../components/AIPerformance/AIPerformance";
import TechnologyPillars from "../components/TechnologyPillars/TechnologyPillars";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import MatrixBackground from "../components/MatrixBackground/MatrixBackground";

const Home: React.FC = () => {
  return (
    <div className="relative min-h-screen bg-[#FAFAFA]">
      <MatrixBackground fontSize="md" endAtSection="#best-in-class-section" />
      <div className="relative z-10">
        <Hero />
        <AIPerformance />
        <div id="best-in-class-section">
          <TechnologyPillars />
        </div>
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
