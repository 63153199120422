import { FC } from "react";
import { motion } from "framer-motion";
import { HiExclamation } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const warnings = [
  {
    icon: HiExclamation,
    title: "GenAI is not a solution to everything",
    gradient: "from-red-900 via-black to-purple-900",
  },
  {
    icon: HiExclamation,
    title: "As with all AI, rubbish in means rubbish out",
    gradient: "from-purple-900 via-black to-red-900",
  },
];

const WarningSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 relative overflow-hidden">
      <div className="absolute inset-0 bg-black" />
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10 w-full">
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8 h-[75vh]">
          {warnings.map((warning, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="relative group h-full"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-br ${warning.gradient} rounded-3xl opacity-80`}
              />
              <div className="absolute inset-0 bg-black/20 rounded-3xl backdrop-blur-sm" />
              <motion.div
                className="relative h-full p-12 flex flex-col items-center justify-center text-center"
                animate={{ y: [0, -10, 0] }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut",
                }}
              >
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.8, 1, 0.8],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                  className="mb-8"
                >
                  <warning.icon className="w-24 h-24 text-red-500" />
                </motion.div>
                <h2 className="desktop:text-6xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold text-white leading-tight">
                  {warning.title}
                </h2>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WarningSlide;
