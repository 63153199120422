import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const AppendixSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="text-center">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-6xl font-bold text-gray-900 mb-8"
        >
          Appendix
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="w-24 h-1 bg-gray-900 mx-auto"
        />
      </div>
    </MatrixSlideLayout>
  );
};

export default AppendixSlide; 