import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiLightningBolt,
  HiClock,
  HiUserGroup,
  HiSparkles,
  HiTrendingUp,
  HiStar,
} from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const industryShift = [
  {
    icon: HiLightningBolt,
    title: "AI Advancements",
    description:
      "LLM technology is still early, use cases still being explored",
    gradient: "from-blue-900 via-blue-800 to-blue-900",
  },
  {
    icon: HiClock,
    title: "The Future",
    description: "In 3 years, every analyst job will be AI augmented",
    gradient: "from-blue-800 via-blue-700 to-blue-800",
  },
  {
    icon: HiUserGroup,
    title: "Adoption",
    description:
      "Analysts need time to train and get used to GenAI e.g. prompting",
    gradient: "from-blue-700 via-blue-600 to-blue-700",
  },
];

const currentLandscape = [
  {
    icon: HiSparkles,
    title: "Early Adoption Phase",
    description: "Most institutions still just beginning to explore LLMs",
    gradient: "from-purple-900 via-purple-800 to-purple-900",
  },
  {
    icon: HiTrendingUp,
    title: "First-Mover Advantage",
    description:
      "Jefferies ahead, already looking to leverage GenAI with Quantly",
    gradient: "from-purple-800 via-purple-700 to-purple-800",
  },
  {
    icon: HiStar,
    title: "Innovation Driver",
    description:
      "Jefferies will set the industry standard for GenAI in research",
    gradient: "from-purple-700 via-purple-600 to-purple-700",
  },
];

const MarketAnalysisSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Market Analysis
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {/* Industry Shift Column */}
        <div className="space-y-6">
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-2xl font-semibold text-gray-900 mb-6"
          >
            Industry Shift
          </motion.h3>
          {industryShift.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-blue-400/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className={`relative bg-gradient-to-br ${item.gradient} p-6 rounded-2xl text-white hover:scale-105 transition-all duration-300 shadow-lg`}
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
              >
                <item.icon className="w-8 h-8 text-white/80 mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h4 className="text-xl font-semibold mb-2 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.title}
                </h4>
                <p className="text-white/80 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.description}
                </p>
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>

        {/* Current Landscape Column */}
        <div className="space-y-6">
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-2xl font-semibold text-gray-900 mb-6"
          >
            Current Landscape
          </motion.h3>
          {currentLandscape.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-purple-400/20 to-purple-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className={`relative bg-gradient-to-br ${item.gradient} p-6 rounded-2xl text-white hover:scale-105 transition-all duration-300 shadow-lg`}
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
              >
                <item.icon className="w-8 h-8 text-white/80 mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h4 className="text-xl font-semibold mb-2 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.title}
                </h4>
                <p className="text-white/80 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.description}
                </p>
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default MarketAnalysisSlide;
