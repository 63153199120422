import { FC } from "react";

interface SlideNumberProps {
  number: number;
  total: number;
}

const SlideNumber: FC<SlideNumberProps> = ({ number, total }) => {
  return (
    <div className="absolute bottom-8 right-8 text-gray-400 font-medium">
      {number}/{total}
    </div>
  );
};

export default SlideNumber;
