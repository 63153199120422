import { FC } from "react";
import { motion } from "framer-motion";
import { IconType } from "react-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface DataPoint {
  month: string;
  value: number;
}

interface DataVisualizationCardProps {
  icon: IconType;
  title: string;
  description: string;
  data: DataPoint[];
  index: number;
}

const DataVisualizationCard: FC<DataVisualizationCardProps> = ({
  icon: Icon,
  title,
  description,
  data,
  index,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
      className="group relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl text-white hover:scale-105 transition-all duration-500"
    >
      <div className="space-y-4">
        <Icon className="w-8 h-8 text-white/80 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />

        <h3 className="text-xl font-semibold group-hover:translate-y-[-2px] transition-transform duration-300">
          {title}
        </h3>

        <p className="text-white/80 text-sm group-hover:translate-y-[-2px] transition-transform duration-300">
          {description}
        </p>

        <div className="h-[150px] w-full opacity-60 group-hover:opacity-100 transition-opacity duration-300">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <XAxis
                dataKey="month"
                stroke="#ffffff"
                strokeOpacity={0.5}
                fontSize={10}
              />
              <YAxis stroke="#ffffff" strokeOpacity={0.5} fontSize={10} />
              <Tooltip
                contentStyle={{
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  border: "none",
                  borderRadius: "8px",
                  color: "#fff",
                }}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#ffffff"
                strokeWidth={2}
                dot={false}
                animationDuration={2000}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
        initial={{ x: "-200%" }}
        animate={{ x: "200%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          ease: "linear",
        }}
      />
    </motion.div>
  );
};

export default DataVisualizationCard;
