import { FC } from "react";
import { motion } from "framer-motion";
import { HiServer, HiDatabase, HiChip, HiUserGroup } from "react-icons/hi";
import MatrixSlideLayout from "./layouts/MatrixSlideLayout";

const capabilities = [
  {
    icon: HiServer,
    title: "White-label Quantly on your infrastructure",
    description:
      "Deploy Quantly seamlessly within your existing infrastructure with full customization options",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
  {
    icon: HiDatabase,
    title: "Integrate your internal research fully",
    description:
      "Connect and leverage all your internal research data and systems within the Quantly platform",
    gradient: "from-purple-500/20 via-indigo-500/20 to-purple-500/20",
  },
  {
    icon: HiChip,
    title: "Leverage cutting edge AI in your entire research operations",
    description:
      "Transform your research processes with state-of-the-art AI technology",
    gradient: "from-indigo-500/20 via-blue-500/20 to-indigo-500/20",
  },
  {
    icon: HiUserGroup,
    title: "Use Quantly as your in-house AI expert team",
    description:
      "Access dedicated AI expertise and support for your research operations",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
];

const AdditionalCapabilitiesSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-4 text-center"
      >
        Additional Capabilities
      </motion.h2>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-xl text-gray-600 mb-12 text-center"
      >
        Quantly can help transform your entire investment research
        infrastructure
      </motion.p>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-6">
        {capabilities.map((capability, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative group"
          >
            <div
              className={`absolute inset-0 bg-gradient-to-r ${capability.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
            />
            <motion.div
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500"
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <capability.icon className="w-8 h-8 text-white mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-xl font-semibold mb-2 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                {capability.title}
              </h3>
              <p className="text-white/80 group-hover:translate-y-[-2px] transition-transform duration-300">
                {capability.description}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </MatrixSlideLayout>
  );
};

export default AdditionalCapabilitiesSlide;
