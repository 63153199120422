import { FC, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import IntroSlide from "../components/Slides/IntroSlide";
import NewsArticlesSlide from "../components/Slides/NewsArticlesSlide";
import AnalystTodaySlide from "../components/Slides/AnalystTodaySlide";
import AIAugmentedAnalystSlide from "../components/Slides/AIAugmentedAnalystSlide";
import SolutionSlide from "../components/ProductSlides/SolutionSlide";
import QuantlyAnalystSlide from "../components/Slides/QuantlyAnalystSlide";
import KeyFeaturesSlide from "../components/ProductSlides/KeyFeaturesSlide";
import QuantlyPlaybooksSlide from "../components/Slides/QuantlyPlaybooksSlide";
import DataAvailabilitySlide from "../components/Slides/DataAvailabilitySlide";
import UseCasesSlide from "../components/Slides/UseCasesSlide";
import BestInClassSlide from "../components/ProductSlides/BestInClassSlide";
import DemoSlide from "../components/ProductSlides/DemoSlide";
import SecurityComplianceSlide from "../components/Slides/SecurityComplianceSlide";
import AnalystTrainingSlide from "../components/Slides/AnalystTrainingSlide";
import TestimonialsSlide from "../components/Slides/TestimonialsSlide";
import AdditionalCapabilitiesSlide from "../components/ProductSlides/AdditionalCapabilitiesSlide";
import ThankYouSlide from "../components/Slides/ThankYouSlide";
import ExportPDFButton from "../components/Slides/ExportPDFButton";

const Product: FC = () => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.swiper?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="h-screen w-screen bg-[#FAFAFA] overflow-hidden">
      <Swiper
        ref={swiperRef}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full"
      >
        <SwiperSlide className="bg-[#FAFAFA]">
          <IntroSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <NewsArticlesSlide />
        </SwiperSlide>{" "}
        <SwiperSlide className="bg-[#FAFAFA]">
          <AnalystTodaySlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AIAugmentedAnalystSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <SolutionSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <KeyFeaturesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <QuantlyAnalystSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <QuantlyPlaybooksSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DataAvailabilitySlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <UseCasesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <BestInClassSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <DemoSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <SecurityComplianceSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AnalystTrainingSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          {" "}
          <TestimonialsSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AdditionalCapabilitiesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ThankYouSlide />
        </SwiperSlide>
      </Swiper>
      <ExportPDFButton />
    </div>
  );
};

export default Product;
