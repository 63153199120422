import React from "react";
import { motion } from "framer-motion";
import { HiCode, HiLightningBolt, HiChip, HiDocumentText, HiHome, HiTemplate, HiGlobeAlt, HiShieldCheck, HiCube } from "react-icons/hi";
import Footer from "../components/Footer/Footer";
import MatrixBackground from "../components/MatrixBackground/MatrixBackground";
import { Link } from "react-router-dom";

const features = [
  {
    icon: HiLightningBolt,
    title: "Purpose-built LLM Orchestration",
    description: "The most accurate LLM system for investment research",
  },
  {
    icon: HiCube,
    title: "Build For Your Use Case",
    description: "Use Quantly to build your own LLM tools",
  },
  {
    icon: HiShieldCheck,
    title: "Private & Secure",
    description: "We do not train on your data",
  },
];

const API: React.FC = () => {
  return (
    <div className="relative min-h-screen bg-[#FAFAFA]">
      <MatrixBackground fontSize="md" endAtSection="#best-in-class-section" />
      <div className="relative z-10">
        {/* Header with Logo and Home Button */}
        <div className="fixed top-0 left-0 w-full z-50 px-8 py-6 flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="flex items-center gap-2"
          >
            <Link to="/">
              <motion.img
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                src="https://createsiteai.b-cdn.net/c/_/4cc4f85d-86a6-46ed-b84c-c3111e974f89"
                alt="Quantly Logo"
                className="desktop:w-12 laptop:w-12 tablet:w-10 mobile:w-8 h-auto"
              />
            </Link>
          </motion.div>
          <Link
            to="/"
            className="group relative px-6 py-2 transition-colors duration-200"
          >
            <span className="relative z-10 text-gray-900 font-medium tracking-wide transition-colors duration-200 group-hover:text-white pointer-events-none flex items-center gap-2">
              <HiHome className="w-5 h-5" />
              Home
            </span>
            <motion.div
              className="absolute inset-0 bg-black rounded-xl -z-0"
              initial={{ scale: 0.95, opacity: 0 }}
              whileHover={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2 }}
            />
          </Link>
        </div>

        {/* Hero Section */}
        <section className="pt-32 pb-8 bg-transparent">
          <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-6xl font-bold text-gray-900 mb-6 text-center"
            >
              Build with Quantly API
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto text-center leading-relaxed"
            >
              Leverage the power of our investment research LLM infrastructure to build
              custom solutions for your organization
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="flex justify-center gap-6"
            >
              <a
                href="https://form.typeform.com/to/uvfk1idp"
                target="_blank"
                rel="noopener noreferrer"
                className="group relative inline-block desktop:px-10 laptop:px-10 tablet:px-10 mobile:px-6 desktop:py-5 laptop:py-5 tablet:py-5 mobile:py-3 bg-black text-white rounded-2xl overflow-hidden transition-all hover:shadow-lg hover:bg-gray-900 duration-300"
              >
                <span className="relative z-10 font-medium tracking-wide">
                  Start Building
                </span>
              </a>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features-section" className="py-16 bg-transparent">
          <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
            <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-6">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="p-6 rounded-2xl bg-white/95 backdrop-blur-md border border-gray-300 hover:shadow-xl hover:border-gray-400 transition-all duration-300"
                >
                  <feature.icon className="w-10 h-10 text-gray-900 mb-4" />
                  <h3 className="text-xl font-bold text-gray-900 mb-3">
                    {feature.title}
                  </h3>
                  <p className="text-gray-700 text-sm leading-relaxed">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Code Example Section */}
        <section className="py-16 bg-transparent">
          <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-gray-900/90 backdrop-blur-sm rounded-2xl p-8 overflow-hidden shadow-xl border border-gray-800"
            >
              <div className="flex items-center gap-2 mb-4">
                <div className="w-3 h-3 rounded-full bg-red-500" />
                <div className="w-3 h-3 rounded-full bg-yellow-500" />
                <div className="w-3 h-3 rounded-full bg-green-500" />
              </div>
              <pre className="text-gray-300 font-mono text-sm">
                <code>{`API_KEY = "your-api-key"
BASE_URL = "https://api.quantly-ai.com/public/v1"

# Create a new playbook
playbook = {
    "title": "New Company Coverage - Industrials",
    "description": "An in-depth report initiating coverage on a new company in the Industrials sector.",
    "steps": [
        {
            "title": "Company segment analysis",
            "prompt": "Describe key segments, products and regions the company operates in."
        },
        {
            "title": "Management Commentary",
            "prompt": "Summarize management's forward guidance and main themes discussed across company segments and products"
        }
    ]
}

# Create the playbook
response = requests.post(
    f"{BASE_URL}/playbooks",
    headers={"Authorization": f"Bearer {API_KEY}"},
    json=playbook
)

# Run the playbook
if response.status_code == 200:
    playbook_id = response.json()["data"]["id"]
    print(f"Created playbook: {playbook_id}")
`}</code>
              </pre>
            </motion.div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 bg-transparent">
          <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
            <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-gray-900 via-black to-gray-900 border border-gray-800">
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(255,255,255,0.1),_transparent_50%)]"></div>
              <div className="relative p-16 flex flex-col items-center">
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(circle_at_bottom_left,_rgba(59,130,246,0.1),_transparent_50%)]"
                />
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  className="text-5xl font-bold text-white mb-6 text-center"
                >
                  Ready to use LLMs for your use case?
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  className="text-xl text-gray-400 mb-12 leading-relaxed max-w-2xl text-center"
                >
                  Join leading investment firms already using Quantly's API to power their research
                </motion.p>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  className="flex justify-center gap-6"
                >
                  <a
                    href="https://form.typeform.com/to/uvfk1idp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative inline-block desktop:px-10 laptop:px-10 tablet:px-10 mobile:px-6 desktop:py-5 laptop:py-5 tablet:py-5 mobile:py-3 border border-white/20 text-white rounded-2xl overflow-hidden transition-all hover:shadow-lg hover:bg-white/10 duration-300"
                  >
                    <span className="relative z-10 font-medium tracking-wide">
                      Contact Sales
                    </span>
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default API; 