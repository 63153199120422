import { FC } from "react";
import { motion } from "framer-motion";
import { HiTemplate, HiCode } from "react-icons/hi";

const AvailabilityOptionsSlide: FC = () => {
  const options = [
    {
      icon: HiTemplate,
      title: "Quantly Web App",
      description: "Use our off-the-shelf Quantly standalone web app and get started straight away",
    },
    {
      icon: HiCode,
      title: "API",
      description: "Use Quantly's purpose-built LLM orchestration to build your own internal tools and workflows",
    },
  ];

  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-5xl font-bold text-gray-900 text-center mb-16"
        >
          Available via
        </motion.h2>

        <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col justify-center items-stretch gap-8">
          {options.map((option, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 + i * 0.1 }}
              className="flex-1 bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl border border-gray-200 transition-all duration-300 flex flex-col"
              whileHover={{ scale: 1.02 }}
            >
              <div className="w-14 h-14 bg-gray-100 rounded-xl flex items-center justify-center mb-6">
                <option.icon className="w-8 h-8 text-gray-900" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {option.title}
              </h3>
              <p className="text-gray-600 text-lg">
                {option.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailabilityOptionsSlide; 