import { FC } from "react";
import { motion } from "framer-motion";
import { HiDatabase, HiRefresh, HiTemplate } from "react-icons/hi";

const features = [
  {
    icon: HiDatabase,
    title: "Data Integrations",
    description: "Seamlessly combine internal and external data sources",
  },
  {
    icon: HiRefresh,
    title: "Real-time Sync",
    description:
      "Automatic updates and synchronization across all data sources",
  },
  {
    icon: HiTemplate,
    title: "Trustworthy Data Partners",
    description: "External data partners include S&P Capital IQ and Bloomberg",
  },
];

const DataSection: FC = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center"
      >
        <div className="desktop:order-2 laptop:order-2 tablet:order-1 mobile:order-1">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Single Source of Truth
          </h2>
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="mb-8"
          >
            {/* Central hub */}
            <motion.circle
              cx="60"
              cy="60"
              r="15"
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.4 }}
            />

            {/* Pulse animation for central hub */}
            <motion.circle
              cx="60"
              cy="60"
              r="8"
              fill="currentColor"
              animate={{ 
                scale: [0.8, 1.2, 0.8],
                opacity: [0.5, 0, 0.5]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          </motion.svg>
        </div>
        <div className="grid gap-6 desktop:order-1 laptop:order-1 tablet:order-2 mobile:order-2">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl transition-all duration-300"
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2, ease: "easeOut" }
              }}
            >
              <feature.icon
                className="w-6 h-6 text-black flex-shrink-0"
              />
              <div>
                <h3 className="text-gray-900 font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default DataSection;
