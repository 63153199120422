import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../../components/ProductSlides/layouts/MatrixSlideLayout";

const IntroSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="flex flex-col items-center justify-center min-h-[80vh]">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          src="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
          alt="Quantly Logo"
          className="desktop:w-48 laptop:w-48 tablet:w-40 mobile:w-32 h-auto mb-12"
        />
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-gray-900 desktop:text-6xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold text-center mb-8"
        >
          Enterprise-grade
          <br />
          <span className="text-gray-400">LLM Investment</span>
          <br />
          <span className="text-gray-400">Research Infrastructure</span>
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="inline-flex items-center gap-2 bg-white border border-gray-100 rounded-full px-6 py-2.5 shadow-sm"
        >
          <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
          <span className="text-gray-800 desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs font-medium tracking-wide">
            AI-POWERED INVESTMENT RESEARCH
          </span>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default IntroSlide;
