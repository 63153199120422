import { FC } from "react";
import { motion } from "framer-motion";
import { HiUserCircle, HiClipboardList, HiTemplate } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const framework = [
  {
    icon: HiUserCircle,
    title: "Role",
    description: "Specify the AI's assumed expertise",
    example: "Act as a financial analyst",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
  {
    icon: HiClipboardList,
    title: "Task",
    description: "Define the specific analysis or output required",
    example: "evaluate Company X's latest earnings report",
    gradient: "from-purple-500/20 via-indigo-500/20 to-purple-500/20",
  },
  {
    icon: HiTemplate,
    title: "Format",
    description: "Indicate the desired form of the response",
    example: "Present your findings in a bullet-point summary",
    gradient: "from-indigo-500/20 via-blue-500/20 to-indigo-500/20",
  },
];

const PromptStructureSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12 text-center"
      >
        Prompt Structuring: Role-Task-Format Framework
      </motion.h2>

      <div className="space-y-8">
        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {framework.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${item.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
              />
              <motion.div
                className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500 min-h-[320px] flex flex-col"
                whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
              >
                <item.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.title}
                </h3>
                <p className="text-white/80 text-lg mb-auto group-hover:translate-y-[-2px] transition-transform duration-300">
                  {item.description}
                </p>
                <div className="bg-white/10 p-4 rounded-xl mt-4">
                  <p className="text-white/60 text-sm italic">{item.example}</p>
                </div>
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10"
        >
          <h3 className="text-xl font-semibold text-white mb-4">Example:</h3>
          <p className="text-white/80 text-lg font-mono p-4 bg-black/30 rounded-xl">
            "As a financial analyst, evaluate Company X's latest earnings
            report. Present your findings in a bullet-point summary."
          </p>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PromptStructureSlide;
