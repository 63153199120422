import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../../components/ProductSlides/layouts/MatrixSlideLayout";

const PivotalMessageSlide: FC = () => {
  return (
    <MatrixSlideLayout isDark>
      <div className="h-full w-full flex items-center justify-center min-h-screen">
        <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center max-w-5xl mx-auto relative"
          >
            <div className="absolute inset-0 bg-white/5 blur-3xl -z-10" />

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-7xl font-bold mb-10 leading-tight"
            >
              <span className="bg-gradient-to-b from-white via-white to-white/70 bg-clip-text text-transparent">
                Financial institutions need a{" "}
              </span>
              <span className="relative">
                <span className="text-white">
                  trusted partner
                </span>
                <motion.span
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ duration: 0.8, delay: 0.5 }}
                  className="absolute -bottom-2 left-0 right-0 h-[2px] bg-gradient-to-r from-transparent via-white to-transparent"
                />
              </span>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-4xl font-light leading-relaxed mb-20"
            >
              <span className="relative inline-block">
                <span className="text-white font-medium">
                  to leverage LLMs effectively
                </span>
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 0.8, delay: 0.7 }}
                  className="absolute bottom-0 left-0 h-[1px] bg-gradient-to-r from-transparent via-zinc-400 to-transparent"
                />
              </span>
            </motion.p>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.8 }}
              className="absolute -z-10 inset-0 overflow-hidden"
            >
              <div className="absolute top-1/2 -left-20 w-16 h-[1px] bg-gradient-to-r from-transparent via-zinc-700 to-transparent" />
              <div className="absolute top-1/2 -right-20 w-16 h-[1px] bg-gradient-to-r from-transparent via-zinc-700 to-transparent" />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 1 }}
              className="flex flex-col items-center gap-2"
            >
              <blockquote className="text-lg text-gray-400 max-w-3xl mx-auto italic font-light">
                "We went with Quantly because they are not just a point solution for analysts. They work to get us used to the platform and partner with us to discuss and build for our specific use cases."
              </blockquote>
              <p className="text-sm text-gray-500 font-medium">
                CTO, $20B+ Global Equities Fund
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PivotalMessageSlide; 