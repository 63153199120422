import { FC, useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface MatrixBackgroundProps {
  fontSize?: "sm" | "md" | "lg";
  endAtSection?: string;
}

const MatrixBackground: FC<MatrixBackgroundProps> = ({
  fontSize = "md",
  endAtSection,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const getFontSize = () => {
    switch (fontSize) {
      case "sm":
        return 24;
      case "md":
        return 36;
      case "lg":
        return 48;
      default:
        return 36;
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight * 3; // Extend height for multiple sections

    const characters = "01";
    const fontSize = getFontSize();
    const columns = canvas.width / fontSize;
    const drops: number[] = [];
    let isAnimating = true;

    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    const draw = () => {
      if (!isAnimating) return;

      ctx.fillStyle = "rgba(250, 250, 250, 0.025)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = characters[Math.floor(Math.random() * characters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const interval = setInterval(draw, 50);

    // Stop animation when reaching the specified section
    if (endAtSection) {
      const targetSection = document.querySelector(endAtSection);
      if (targetSection) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                isAnimating = false;
                if (observerRef.current) {
                  observerRef.current.disconnect();
                }
              }
            });
          },
          { threshold: 0.5 },
        );
        observerRef.current.observe(targetSection);
      }
    }

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight * 3;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fontSize, endAtSection]);

  return (
    <motion.canvas
      ref={canvasRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="fixed inset-0 pointer-events-none z-0"
      style={{ height: "300vh" }} // Extend height for multiple sections
    />
  );
};

export default MatrixBackground;