import { FC } from "react";
import { motion } from "framer-motion";
import ParticlesBackground from "../Slides/components/ParticlesBackground";

const ImportantMessageSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <ParticlesBackground />
      </div>
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
          <motion.div
            className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-16 rounded-3xl border border-white/10 backdrop-blur-sm"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold text-white text-center leading-tight"
            >
              AI enhances, does not replace analyst judgement
            </motion.h2>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ImportantMessageSlide;
