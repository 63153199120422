import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiExclamationCircle,
  HiDatabase,
  HiDocumentSearch,
  HiDocumentText,
  HiChartBar,
} from "react-icons/hi";
import CircularProgress from "./components/CircularProgress";

const points = [
  {
    icon: HiDocumentSearch,
    title: "Manual information gathering",
  },
  {
    icon: HiChartBar,
    title: "Manual data cleaning & analysis",
  },
  {
    icon: HiDatabase,
    title: "Siloed information sources",
  },
  {
    icon: HiDocumentText,
    title: "Report writing from scratch",
  },
];

const AnalystTodaySlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-12"
        >
          <h2 className="text-5xl font-bold text-gray-900">
            The Analyst Today
          </h2>
        </motion.div>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          <div className="space-y-6">
            {points.map((point, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="flex items-center gap-6 bg-red-50 p-8 rounded-2xl border border-red-100 hover:shadow-lg transition-all duration-300"
              >
                <point.icon className="w-10 h-10 text-red-500 flex-shrink-0" />
                <h3 className="text-xl font-semibold text-gray-900">
                  {point.title}
                </h3>
              </motion.div>
            ))}
          </div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="desktop:block laptop:block tablet:hidden mobile:hidden bg-red-50 p-12 rounded-2xl border border-red-100"
          >
            <div className="flex flex-col items-center">
              <CircularProgress percentage={70} />
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 1 }}
                className="text-center mt-8"
              >
                <p className="text-2xl text-gray-900 font-light leading-relaxed">
                  of analyst time spent on manual tasks that can be augmented by
                  AI
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AnalystTodaySlide;
