import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiDocumentSearch,
  HiChartBar,
  HiCube,
  HiDatabase,
  HiUserGroup,
  HiCode,
} from "react-icons/hi";

const advantages = [
  {
    icon: HiDocumentSearch,
    title: "Source Attribution",
    description:
      "Every Quantly output can be referenced and traced back to its original source - a PDF file directly or a table.",
  },
  {
    icon: HiChartBar,
    title: "Better Accuracy",
    description:
      "Quantly significantly outperforms all general models, as well as all competitors.",
  },
  {
    icon: HiCube,
    title: "Unlimited Context Window",
    description:
      "Quantly spawns as many agents as needed to answer a research question. Context window is not a limitation.",
  },
  {
    icon: HiDatabase,
    title: "Seamless Data Access",
    description:
      "Apart from 3rd party data sources, Quantly can easily connect to any internal source of data, in a secure and compliant way.",
  },
  {
    icon: HiUserGroup,
    title: "Automatic Prompt Improvement",
    description:
      "Quantly automatically optimises analyst prompting to boost AI literacy and maximise output",
  },
  {
    icon: HiCode,
    title: "Calculations",
    description:
      "Quantly turns text-to-code when doing calculations to prevent hallucinations.",
  },
];

const WhyBetterSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative">
        <div className="text-center mb-12">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-5xl font-bold text-gray-900 mb-4"
          >
            Why Is Quantly Better Than Other LLM Tools?
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-gray-600"
          >
            Purpose-built for investment research
          </motion.p>
        </div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {advantages.map((advantage, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group h-[250px]"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              <motion.div
                className="relative bg-white shadow-lg border border-gray-100 p-8 rounded-2xl hover:shadow-xl transition-all duration-300 h-full flex flex-col"
                whileHover={{ scale: 1.02 }}
              >
                <advantage.icon className="w-10 h-10 text-gray-900 mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300 flex-shrink-0" />
                <h3 className="text-xl font-semibold text-gray-900 mb-3 group-hover:translate-y-[-2px] transition-transform duration-300 flex-shrink-0">
                  {advantage.title}
                </h3>
                <p className="text-gray-600 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300 flex-grow">
                  {advantage.description}
                </p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyBetterSlide; 