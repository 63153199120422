import { FC } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { HiArrowRight } from "react-icons/hi";
import TopBadge from "./content/TopBadge";
import MainContent from "./content/MainContent";
import StatsPanel from "./content/StatsPanel";

const ContentContainer: FC = () => {
  return (
    <div className="relative desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
      <div className="desktop:pt-32 laptop:pt-28 tablet:pt-24 mobile:pt-20">
        <TopBadge />
        <div className="grid desktop:grid-cols-12 laptop:grid-cols-12 tablet:grid-cols-1 mobile:grid-cols-1 gap-20">
          <MainContent />
          <StatsPanel />
        </div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-20"
        >
          <Link 
            to="/api" 
            className="relative block p-8 rounded-3xl overflow-hidden group hover:scale-[1.02] transform transition-all duration-300 bg-gradient-to-r from-gray-900 via-black to-gray-900"
          >
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(255,255,255,0.1),_transparent_50%)]"></div>
            <div className="relative flex items-center justify-between">
              <div>
                <div className="text-gray-400 text-sm font-medium mb-2">DEVELOPER API</div>
                <div className="text-white text-xl font-semibold">
                  Building for your own use cases internally?
                </div>
              </div>
              <div className="flex items-center">
                <span className="text-white text-lg font-medium mr-2">Check out our API</span>
                <HiArrowRight className="w-6 h-6 text-white transform group-hover:translate-x-1 transition-transform duration-300" />
              </div>
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default ContentContainer;
