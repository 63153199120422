import { FC } from "react";
import { motion } from "framer-motion";
import { HiArrowRight } from "react-icons/hi";
import MatrixBackgroundFinal from "./components/MatrixBackgroundFinal";

const FinalCallToActionSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <MatrixBackgroundFinal />
      </div>
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <div className="flex flex-col items-center justify-center text-center">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-6xl font-bold text-white mb-8"
          >
            Try GenAI in Practice
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-2xl text-white/80 mb-12"
          >
            Experience the power of AI-augmented investment research firsthand
          </motion.p>
          <motion.a
            href="https://app.quantly-ai.com/"
            target="_blank"
            rel="noopener noreferrer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="group inline-flex items-center gap-3 bg-white text-black px-8 py-4 rounded-xl text-xl font-semibold hover:scale-105 transition-all duration-300"
          >
            Go to Quantly App
            <HiArrowRight className="w-6 h-6 transform group-hover:translate-x-1 transition-transform duration-300" />
          </motion.a>
        </div>
      </div>
    </div>
  );
};

export default FinalCallToActionSlide;
