import { FC } from "react";
import { motion } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const IntroductionSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="text-center">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          src="https://createsiteai.b-cdn.net/c/_/2ab574a8-5dff-437d-b2b7-864fb3b6c6b2"
          alt="Quantly Logo"
          className="desktop:w-48 laptop:w-48 tablet:w-40 mobile:w-32 h-auto mx-auto mb-12"
        />{" "}
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-6xl font-bold text-gray-900 mb-8"
        >
          GenAI in Investment Research
        </motion.h2>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="text-3xl font-medium text-gray-600"
        >
          Introduction for analysts
        </motion.h2>
      </div>
    </MatrixSlideLayout>
  );
};

export default IntroductionSlide;
