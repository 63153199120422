import { FC } from "react";
import { motion } from "framer-motion";
import { HiCog, HiDatabase, HiShieldCheck, HiCloud } from "react-icons/hi";
import MatrixSlideLayout from "../../ProductSlides/layouts/MatrixSlideLayout";

const steps = [
  {
    icon: HiCog,
    title: "Set Parameters",
    description:
      "Identify topic/question/KPI, set a timeframe and company universe to analyse",
  },
  {
    icon: HiDatabase,
    title: "Data Processing",
    description:
      "Quantly processes the data and transforms it into a clean dataset",
  },
  {
    icon: HiShieldCheck,
    title: "Data QA",
    description: "Quantly does QA on a sample of the data to ensure accuracy",
  },
  {
    icon: HiCloud,
    title: "Delivery",
    description:
      "Receive the dataset as a one-off or regularly, in your preferred format: API or flat file",
  },
];

const DesignFour: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-12"
      >
        Answer any question at scale
      </motion.h2>

      <div className="relative flex justify-center items-center">
        <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4 items-center">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              {/* Hexagonal Node */}
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="group relative"
              >
                {/* Hexagon shape with gradient background */}
                <div className="w-72 h-80">
                  <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800 clip-hexagon transform hover:scale-105 transition-all duration-300">
                    <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
                      <div className="w-16 h-16 bg-gray-800 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                        <step.icon className="w-8 h-8 text-white" />
                      </div>
                      <h3 className="text-xl font-semibold text-white mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
                        {step.title}
                      </h3>
                      <p className="text-gray-300 text-sm group-hover:translate-y-[-2px] transition-transform duration-300">
                        {step.description}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Connecting line with animated flow (except for last item) */}
                {index < steps.length - 1 && (
                  <div className="absolute top-1/2 desktop:left-full laptop:left-full tablet:bottom-0 mobile:bottom-0 desktop:-translate-y-1/2 laptop:-translate-y-1/2 tablet:translate-y-0 mobile:translate-y-0 desktop:w-16 laptop:w-16 tablet:w-1 mobile:w-1 desktop:h-0.5 laptop:h-0.5 tablet:h-8 mobile:h-8 bg-gradient-to-r from-gray-700 via-gray-600 to-gray-700">
                    {" "}
                    <div className="absolute inset-0 bg-gradient-to-r from-gray-600/20 via-gray-400/20 to-gray-600/20" />
                  </div>
                )}
              </motion.div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .clip-hexagon {
          clip-path: polygon(
            50% 0%,
            100% 25%,
            100% 75%,
            50% 100%,
            0% 75%,
            0% 25%
          );
        }
      `}</style>
    </MatrixSlideLayout>
  );
};

export default DesignFour;
