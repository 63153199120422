import { FC } from "react";
import {
  HiDocumentText,
  HiChartBar,
  HiLightBulb,
  HiSearch,
  HiTemplate,
  HiCog,
} from "react-icons/hi";

const tasks = [
  {
    icon: HiDocumentText,
    title: "Large-scale Text Processing",
    description:
      "Analyze thousands of financial documents, earnings calls, and reports simultaneously",
  },
  {
    icon: HiChartBar,
    title: "Pattern Recognition",
    description:
      "Identify trends and correlations across multiple companies and time periods",
  },
  {
    icon: HiCog,
    title: "Information Synthesis",
    description:
      "Combine insights from diverse sources to create comprehensive market views",
  },
  {
    icon: HiSearch,
    title: "Initial Screening",
    description:
      "Quickly evaluate potential investment opportunities against defined criteria",
  },
  {
    icon: HiLightBulb,
    title: "Idea Generation",
    description:
      "Discover new investment angles by analyzing cross-market relationships",
  },
  {
    icon: HiTemplate,
    title: "Report Formatting and Creation",
    description:
      "Automate the structuring and formatting of research reports and presentations",
  },
];

const TasksSuitedSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-800" />
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Tasks Best Suited for AI Assistance
        </h2>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
          {tasks.map((task, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl border border-white/10 backdrop-blur-sm min-h-[280px] flex flex-col"
            >
              <task.icon className="w-12 h-12 mb-4 text-white" />
              <h3 className="text-2xl font-semibold mb-4 text-white">
                {task.title}
              </h3>
              <p className="text-white/80 text-lg">{task.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TasksSuitedSlide;
