import { FC, ReactNode } from "react";
import { motion } from "framer-motion";
import MatrixBackground from "../components/MatrixBackground";

interface MatrixSlideLayoutProps {
  children: ReactNode;
  isDark?: boolean;
}

const MatrixSlideLayout: FC<MatrixSlideLayoutProps> = ({ children, isDark = false }) => {
  return (
    <div className={`h-screen w-full flex items-center justify-center p-12 relative overflow-hidden ${isDark ? 'bg-black' : 'bg-[#FAFAFA]'}`}>
      <MatrixBackground isDark={isDark} />
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto relative z-10 w-full">
        {children}
      </div>
    </div>
  );
};

export default MatrixSlideLayout;
