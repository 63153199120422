import { FC } from "react";
import { motion } from "framer-motion";
import { HiCode, HiDocument } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const deliveryMethods = [
  {
    icon: HiCode,
    title: "API",
    description:
      "Access data programmatically through our secure API endpoints",
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20",
  },
  {
    icon: HiDocument,
    title: "Flat file",
    description: "Receive structured data in your preferred file format",
    gradient: "from-purple-500/20 via-indigo-500/20 to-purple-500/20",
  },
];

const DeliveryMethodSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-6xl font-bold text-gray-900 mb-4 text-center"
      >
        Delivery Method
      </motion.h2>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-xl text-gray-600 mb-12 text-center"
      >
        Receive data via your preferred delivery method
      </motion.p>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
        {deliveryMethods.map((method, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            className="relative group"
          >
            <div
              className={`absolute inset-0 bg-gradient-to-r ${method.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700`}
            />
            <motion.div
              className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-[1.02] transition-all duration-500"
              whileHover={{ boxShadow: "0 0 30px rgba(0,0,0,0.2)" }}
            >
              <method.icon className="w-12 h-12 mb-6 text-white transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
              <h3 className="text-2xl font-semibold mb-4 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                {method.title}
              </h3>
              <p className="text-white/80 text-lg group-hover:translate-y-[-2px] transition-transform duration-300">
                {method.description}
              </p>
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                initial={{ x: "-200%" }}
                animate={{ x: "200%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
              />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </MatrixSlideLayout>
  );
};

export default DeliveryMethodSlide;
