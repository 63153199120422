import { FC, useState } from "react";
import DesignOne from "./DesignOne";
import DesignFour from "./DesignFour";
import DesignTwo from "./DesignTwo";
import DesignThree from "./DesignThree";

// You can uncomment and use this component if you want to let the customer switch between designs
// during development. For production, you would just export the chosen design directly.

const ThinkAnyQuestionSlide: FC = () => {
  // For now, we'll just return DesignOne as the default
  return <DesignFour />;

  // Uncomment below to enable design switching during development
  /*
  const [currentDesign, setCurrentDesign] = useState(1);

  const designs = {
    1: <DesignOne />,
    2: <DesignTwo />,
    3: <DesignThree />,
  };

  return (
    <div className="relative">
      {designs[currentDesign as keyof typeof designs]}
      <div className="absolute bottom-4 right-4 flex gap-2">
        <button
          onClick={() => setCurrentDesign(1)}
          className={`px-4 py-2 rounded-lg ${
            currentDesign === 1 ? "bg-black text-white" : "bg-gray-200"
          }`}
        >
          Design 1
        </button>
        <button
          onClick={() => setCurrentDesign(2)}
          className={`px-4 py-2 rounded-lg ${
            currentDesign === 2 ? "bg-black text-white" : "bg-gray-200"
          }`}
        >
          Design 2
        </button>
        <button
          onClick={() => setCurrentDesign(3)}
          className={`px-4 py-2 rounded-lg ${
            currentDesign === 3 ? "bg-black text-white" : "bg-gray-200"
          }`}
        >
          Design 3
        </button>
      </div>
    </div>
  );
  */
};

export default ThinkAnyQuestionSlide;
