import { FC } from "react";
import { motion } from "framer-motion";
import { HiArrowRight } from "react-icons/hi";
import MatrixSlideLayout from "../../components/ProductSlides/layouts/MatrixSlideLayout";

const QuantlyInActionSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="text-center">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Let's automate a manual workflow:
          <br />
          <span className="text-gray-600">Starting coverage of a new company</span>
        </motion.h2>

        <motion.a
          href="https://app.quantly-ai.com/"
          target="_blank"
          rel="noopener noreferrer"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          whileHover={{ 
            scale: 1.02,
            transition: { duration: 0.2 }
          }}
          whileTap={{ scale: 0.98 }}
          className="group relative inline-flex items-center gap-3 bg-black text-white px-8 py-4 rounded-xl border border-white/10 shadow-2xl hover:shadow-3xl transition-all duration-300"
        >
          <span className="text-lg font-semibold tracking-wide">
            See Quantly in Action
          </span>
          <HiArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" />
        </motion.a>
      </div>
    </MatrixSlideLayout>
  );
};

export default QuantlyInActionSlide;
