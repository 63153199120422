import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiLightningBolt,
  HiUserGroup,
  HiLightBulb,
  HiChat,
} from "react-icons/hi";

const outcomes = [
  {
    icon: HiLightningBolt,
    title: "Future-Ready Investment Research Infrastructure",
    description:
      "Strong foundations to continue building for more complex use cases",
    iconGradient: "from-blue-400 to-blue-600",
  },
  {
    icon: HiUserGroup,
    title: "Every Analyst Gets An Assistant",
    description:
      "Every analyst will have a powerful helping hand, an extension of them",
    iconGradient: "from-purple-400 to-purple-600",
  },
  {
    icon: HiLightBulb,
    title: "Higher Analyst Value Add",
    description:
      "Shift from routine manual tasks to generating unique insights and deeper analysis",
    iconGradient: "from-green-400 to-green-600",
  },
  {
    icon: HiChat,
    title: "Stronger Research Product",
    description:
      "More time for high-value client interactions and faster research delivery",
    iconGradient: "from-orange-400 to-orange-600",
  },
];

const OutcomeSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-5xl font-bold text-gray-900 text-center mb-12"
        >
          Business Outcome
        </motion.h2>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {outcomes.map((outcome, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 40, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: index * 0.2,
                type: "spring",
                stiffness: 100,
              }}
              className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl border border-gray-100 transition-all duration-300 group"
              whileHover={{ scale: 1.02 }}
            >
              <div
                className="w-14 h-14 bg-gray-100 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300"
              >
                <outcome.icon className="w-7 h-7 text-gray-900" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
                {outcome.title}
              </h3>
              <p className="text-gray-600 leading-relaxed text-base group-hover:translate-y-[-2px] transition-transform duration-300">
                {outcome.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OutcomeSlide;
