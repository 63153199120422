import { FC, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

interface PromptChainStep {
  id: number;
  prompt: string;
  response: string;
}

const chainSteps: PromptChainStep[] = [
  {
    id: 1,
    prompt: "Summarize key financial metrics for Company B.",
    response:
      "Company B shows strong revenue growth at 15% YoY, with EBITDA margins of 25%. However, operating cash flow has declined 10% and debt/EBITDA ratio increased to 3.5x.",
  },
  {
    id: 2,
    prompt: "Based on these metrics, identify potential areas of concern.",
    response:
      "Key concerns include declining cash flow despite revenue growth, suggesting potential working capital issues. The rising leverage ratio could limit financial flexibility and increase interest burden.",
  },
  {
    id: 3,
    prompt:
      "Analyze how these concerns might impact the company's 5-year growth projections.",
    response:
      "The working capital constraints and high leverage could hamper Company B's ability to fund future growth. This may result in slower expansion, reduced market share gains, and potential need for equity raising.",
  },
];

const PromptChainingSlide: FC = () => {
  const [expandedStep, setExpandedStep] = useState<number | null>(null);
  const [visibleSteps, setVisibleSteps] = useState<number[]>([1]);

  const handleStepClick = (stepId: number) => {
    setExpandedStep(expandedStep === stepId ? null : stepId);
    if (!visibleSteps.includes(stepId + 1) && stepId < chainSteps.length) {
      setVisibleSteps([...visibleSteps, stepId + 1]);
    }
  };

  return (
    <MatrixSlideLayout>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900 mb-16"
      >
        Prompt Chaining
      </motion.h2>

      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12 items-start">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-8 rounded-2xl text-white"
        >
          <p className="text-xl mb-6">
            Link multiple prompts in a logical sequence to break down complex
            financial analyses into manageable steps.
          </p>
          <p className="text-xl text-white/80">
            Each prompt builds on the previous one, creating a comprehensive
            analytical process.
          </p>
        </motion.div>

        <div className="relative">
          <div className="absolute left-8 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500/50 via-purple-500/50 to-blue-500/50" />

          <div className="space-y-8">
            {chainSteps.map((step) => (
              <AnimatePresence key={step.id}>
                {visibleSteps.includes(step.id) && (
                  <motion.div
                    initial={{ opacity: 0, x: 20, height: 0 }}
                    animate={{ opacity: 1, x: 0, height: "auto" }}
                    transition={{ duration: 0.6 }}
                    className="relative pl-16"
                  >
                    <motion.div
                      className="w-4 h-4 rounded-full bg-blue-500 absolute left-6 top-4 -translate-x-1/2"
                      animate={{
                        scale: [1, 1.2, 1],
                        opacity: [1, 0.8, 1],
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse",
                      }}
                    />

                    <div
                      onClick={() => handleStepClick(step.id)}
                      className="bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl text-white cursor-pointer hover:scale-[1.02] transition-all duration-300"
                    >
                      <p className="text-lg font-mono mb-4">{step.prompt}</p>

                      <AnimatePresence>
                        {expandedStep === step.id && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="overflow-hidden"
                          >
                            <div className="border-t border-white/10 pt-4 mt-4">
                              <p className="text-white/80">{step.response}</p>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PromptChainingSlide;
