import { FC } from "react";
import { motion } from "framer-motion";
import { HiUserGroup, HiCurrencyDollar, HiChartBar } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const benefits = [
  {
    icon: HiUserGroup,
    title: "Volume Discounts",
    description: "Price per analyst decreases with more users",
    gradient: "from-blue-900 via-blue-800 to-blue-900",
  },
  {
    icon: HiCurrencyDollar,
    title: "Cost-Effective Solution",
    description: "Affordable way to implement GenAI across research",
    gradient: "from-purple-900 via-purple-800 to-purple-900",
  },
];

const PricingAnalysisSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col items-center justify-center bg-gradient-to-br from-gray-900 via-black to-gray-900 p-12 rounded-2xl"
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-white text-center"
          >
            <div className="text-7xl font-bold mb-4">$800</div>
            <div className="text-xl text-gray-400 mb-8">
              per analyst, per year
            </div>{" "}
            <div className="text-3xl font-semibold text-gray-300">
              $66<span className="text-xl text-gray-400">/month</span>
            </div>
            <div className="text-sm text-gray-400 mt-4">
              Based on 50 analysts
            </div>
          </motion.div>
        </motion.div>

        <div className="space-y-6">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-br ${benefit.gradient} blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-700`}
              />
              <motion.div
                className="relative bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 rounded-2xl border border-white/10 backdrop-blur-sm hover:scale-105 transition-all duration-500"
                whileHover={{ boxShadow: "0 0 40px rgba(0,0,0,0.3)" }}
              >
                <benefit.icon className="w-8 h-8 text-white mb-4 transform group-hover:scale-110 group-hover:rotate-3 transition-all duration-300" />
                <h3 className="text-xl font-semibold mb-2 text-white group-hover:translate-y-[-2px] transition-transform duration-300">
                  {benefit.title}
                </h3>
                <p className="text-white/80 group-hover:translate-y-[-2px] transition-transform duration-300">
                  {benefit.description}
                </p>
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 rounded-2xl"
                  initial={{ x: "-200%" }}
                  animate={{ x: "200%" }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PricingAnalysisSlide;
