import { FC } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { HiLightningBolt, HiTrendingUp, HiChartBar } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";
import DataVisualizationCard from "./components/DataVisualizationCard";
import TiltingCard from "./components/TiltingCard";

const metrics = [
  {
    icon: HiLightningBolt,
    title: "Guidance Changes over time",
    description:
      "Extract management guidance changes across the whole market, over years.",
    data: [
      { month: "Jan", value: 65 },
      { month: "Feb", value: 72 },
      { month: "Mar", value: 68 },
      { month: "Apr", value: 75 },
      { month: "May", value: 82 },
      { month: "Jun", value: 78 },
    ],
  },
  {
    icon: HiTrendingUp,
    title: "Product Pricing Sentiment",
    description:
      "How does management talk about product pricing? Positive, negative or neutral over years of calls?",
    data: [
      { month: "Jan", value: 45 },
      { month: "Feb", value: 52 },
      { month: "Mar", value: 48 },
      { month: "Apr", value: 58 },
      { month: "May", value: 62 },
      { month: "Jun", value: 55 },
    ],
  },
  {
    icon: HiChartBar,
    title: "Non-standard KPIs",
    description:
      "Did management mention residential construction? What was the sentiment around the topic? We can ask that question over 10 years, for each company we care about.",
    data: [
      { month: "Jan", value: 35 },
      { month: "Feb", value: 42 },
      { month: "Mar", value: 38 },
      { month: "Apr", value: 45 },
      { month: "May", value: 52 },
      { month: "Jun", value: 48 },
    ],
  },
];

const DataExtractionSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="space-y-8">
        <div className="flex flex-col items-start">
          <motion.div
            className="group relative inline-block cursor-pointer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold text-gray-900">
              Example Datasets
            </h2>
            <motion.div
              className="absolute bottom-0 left-0 h-0.5 w-0 bg-black"
              initial={{ width: "0%" }}
              whileHover={{ width: "100%" }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        </div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-6">
          {metrics.map((metric, index) => (
            <DataVisualizationCard
              key={index}
              icon={metric.icon}
              title={metric.title}
              description={metric.description}
              data={metric.data}
              index={index}
            />
          ))}
        </div>
      </div>
    </MatrixSlideLayout>
  );
};

export default DataExtractionSlide;
