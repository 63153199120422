import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiDatabase, HiServer, HiCode } from "react-icons/hi";

const features = [
  {
    icon: HiLightningBolt,
    title: "Faster, Better Research",
    description: "Boost productivity and improve insights with LLMs",
  },
  {
    icon: HiDatabase,
    title: "Internal Data Integration",
    description: "Integrate any internal data source alongside external data sources",
  },
  {
    icon: HiServer,
    title: "On-premise or Hybrid Deployment",
    description: "Deploy Quantly on your infrastructure or on ours",
  },
  {
    icon: HiCode,
    title: "API or Web App",
    description: "Use the Quantly platform or build for your own internal use cases",
  },
];

const StatsPanel: FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className="desktop:col-span-5 laptop:col-span-5 tablet:col-span-1 mobile:col-span-1 desktop:mt-0 laptop:mt-0 tablet:mt-8 mobile:mt-8"
    >
      <div className="flex flex-col space-y-4">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.2 + index * 0.1 }}
            className="bg-white rounded-2xl p-6 border border-gray-100 shadow-sm hover:shadow-lg transition-shadow duration-300"
          >
            <div className="flex items-start space-x-4">
              <div className="w-10 h-10 bg-black rounded-xl flex items-center justify-center transition-transform duration-300 flex-shrink-0">
                <feature.icon className="w-5 h-5 text-white" />
              </div>
              <div className="flex-1">
                <h3 className="text-gray-900 font-semibold mb-2 transition-transform duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-500 text-sm leading-relaxed">
                  {feature.description}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default StatsPanel;
