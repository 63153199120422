import { FC, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import IntroSlide from "../components/Slides/IntroSlide";
import NewsArticlesSlide from "../components/Slides/NewsArticlesSlide";
import AnalystTodaySlide from "../components/Slides/AnalystTodaySlide";
import AIAugmentedAnalystSlide from "../components/Slides/AIAugmentedAnalystSlide";
import ChallengesSlide from "../components/Slides/ChallengesSlide";
import WhoWeAreSlide from "../components/Slides/WhoWeAreSlide";
import WhatWeDoAltSlide from "../components/Slides/WhatWeDoAltSlide";
import AvailabilityOptionsSlide from "../components/Slides/AvailabilityOptionsSlide";
import WhyBetterSlide from "../components/Slides/WhyBetterSlide";
import SecurityComplianceSlide from "../components/Slides/SecurityComplianceSlide";
import BestInClassSlide from "../components/ProductSlides/BestInClassSlide";
import AnalystTrainingSlide from "../components/Slides/AnalystTrainingSlide";
import OutcomeSlide from "../components/Slides/OutcomeSlide";
import QuantlyInActionSlide from "../components/Slides/QuantlyInActionSlide";
import SolutionSlide from "../components/ProductSlides/SolutionSlide";
import QuantlyAnalystSlide from "../components/Slides/QuantlyAnalystSlide";
import QuantlyPlaybooksSlide from "../components/Slides/QuantlyPlaybooksSlide";
import DataAvailabilitySlide from "../components/Slides/DataAvailabilitySlide";
import DemoSlide from "../components/ProductSlides/DemoSlide";
import TestimonialsSlide from "../components/Slides/TestimonialsSlide";
import ThankYouSlide from "../components/Slides/ThankYouSlide";
import ExportPDFButton from "../components/Slides/ExportPDFButton";
import SlideNumber from "../components/Slides/components/SlideNumber";
import PivotalMessageSlide from "../components/Slides/PivotalMessageSlide";
import QuantlyTodaySlide from "../components/Slides/QuantlyTodaySlide";
import QuantlyFutureSlide from "../components/Slides/QuantlyFutureSlide";
import DeliveryOptionsSlide from "../components/Slides/DeliveryOptionsSlide";
import AppendixSlide from "../components/Slides/AppendixSlide";

const Slides: FC = () => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.swiper?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.swiper?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="h-screen w-screen bg-[#FAFAFA] overflow-hidden"
    >
      <Swiper
        ref={swiperRef}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full desktop:p-0 laptop:p-0 tablet:p-0 mobile:p-4"
      >
        {" "}
        {[
          { Component: IntroSlide },
          { Component: WhoWeAreSlide },
          { Component: WhatWeDoAltSlide },
          { Component: AvailabilityOptionsSlide },
          { Component: WhyBetterSlide },
          { Component: NewsArticlesSlide },
          { Component: AnalystTodaySlide },
          { Component: AIAugmentedAnalystSlide },
          { Component: ChallengesSlide },
          { Component: PivotalMessageSlide },
          { Component: BestInClassSlide },
          { Component: AnalystTrainingSlide },
          { Component: OutcomeSlide },
          { Component: QuantlyTodaySlide },
          { Component: QuantlyFutureSlide },
          { Component: DeliveryOptionsSlide },
          { Component: SecurityComplianceSlide },
          { Component: QuantlyInActionSlide },
          { Component: ThankYouSlide },
          { Component: AppendixSlide },
          { Component: SolutionSlide },
          { Component: QuantlyAnalystSlide },
          { Component: QuantlyPlaybooksSlide },
          { Component: DataAvailabilitySlide },
          { Component: DemoSlide },
          { Component: TestimonialsSlide },
        ].map(({ Component }, index) => (
          <SwiperSlide
            key={index}
            className="bg-[#FAFAFA] desktop:overflow-hidden laptop:overflow-hidden tablet:overflow-auto mobile:overflow-auto"
          >
            <div className="min-h-screen w-full desktop:p-0 laptop:p-0 tablet:py-16 mobile:py-16 flex items-center justify-center relative">
              <Component />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <ExportPDFButton />
    </motion.div>
  );
};

export default Slides;
