import { FC } from "react";
import { motion } from "framer-motion";
import { HiExclamationCircle, HiCheckCircle } from "react-icons/hi";
import MatrixSlideLayout from "../ProductSlides/layouts/MatrixSlideLayout";

const PromptComparisonSlide: FC = () => {
  return (
    <MatrixSlideLayout>
      <div className="flex flex-col items-center justify-center min-h-[80vh] max-w-4xl mx-auto">
        {/* Poor Prompt Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="w-full bg-gradient-to-br from-red-900/90 to-gray-900/90 p-8 rounded-2xl mb-8 relative"
        >
          <div className="flex items-start gap-4">
            <HiExclamationCircle className="w-8 h-8 text-red-500 flex-shrink-0" />
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">
                Poor Prompt Example
              </h3>
              <p className="text-white/80 text-lg font-mono p-4 bg-black/30 rounded-xl mb-4">
                "Sales of Boeing?"
              </p>
              <p className="text-red-300">
                A vague prompt that leaves a lot to the model's interpretation =
                Poor result
              </p>
            </div>
          </div>
        </motion.div>

        {/* Connecting Arrow */}
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 80 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="w-0.5 bg-gradient-to-b from-red-500 to-green-500 relative"
        >
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: [0, 80, 0] }}
            transition={{ duration: 2, repeat: Infinity }}
            className="absolute top-0 w-2 h-2 -left-[3px] bg-white rounded-full"
          />
        </motion.div>

        {/* Good Prompt Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="w-full bg-gradient-to-br from-green-900/90 to-gray-900/90 p-8 rounded-2xl relative"
        >
          <div className="flex items-start gap-4">
            <HiCheckCircle className="w-8 h-8 text-green-500 flex-shrink-0" />
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">
                Good Prompt Example
              </h3>
              <p className="text-white/80 text-lg font-mono p-4 bg-black/30 rounded-xl mb-4">
                "You are an equity analyst covering Boeing. Your task is to
                output a table of Boeing's sales over the past 4 quarters and
                the YoY% growth rate. Go through the last 4 quarterly reports to
                find the information."
              </p>
              <p className="text-green-300">
                A well-structured prompt with explicit instructions, a clear
                context, and a defined desired outcome = Good result
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </MatrixSlideLayout>
  );
};

export default PromptComparisonSlide;
