import { FC } from "react";
import { motion } from "framer-motion";
import { HiUserCircle } from "react-icons/hi";

const testimonials = [
  {
    quote:
      "Quantly is a game changer for our daily work. It is very user friendly and enables us to extract exact and reliable key metrics with great speed. The tool also is strong to capture outlook data and do analysis. It is almost like having a new member in the team.",
    author: "Marnik Hinnekens",
    title: "Director of Credit Research, TP ICAP",
  },
  {
    quote:
      "Quantly is an integral part of my daily research workflow. It helps me go through regulatory reports, earnings calls and other company docs 10x faster than before. It gives me insights that I could have missed and allows me to go through a lot more coverage!",
    author: "Sell Side Analyst",
    title: "Large US Investment Bank",
  },
  {
    quote:
      "Quantly allows me to quickly cover a lot of ground very fast, both high-level and more granular, depending on my query. It also provides a neutral assessment, which you soon realize is rare. I love using the product!",
    author: "Oliver Davey",
    title: "Managing Partner, Mara River Capital",
  },
];

const TestimonialsSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          Trusted by 150+ Buy & Sell Side Analysts
        </motion.h2>
        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white shadow-xl border border-gray-100 p-8 rounded-2xl hover:shadow-2xl hover:bg-gray-50 transition-all duration-300"
            >
              <div className="space-y-4">
                <p className="text-gray-600 text-lg leading-relaxed">
                  "{testimonial.quote}"
                </p>
                <div>
                  <div className="font-medium text-gray-900">
                    {testimonial.author}
                  </div>
                  <div className="text-gray-500">{testimonial.title}</div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlide;
